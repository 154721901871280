import React from "react"
import { Box, Card, MentionInput } from "@atoms"
import { Comment } from "@molecules"
import {
  Icons,
  LinkButton,
  PillButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import * as SC from "./styles"

type Comments = React.ComponentProps<typeof Comment> & { order: number }

type CommentaryProps = {
  comments: Comments[]
  mentionCallback: React.ComponentProps<typeof MentionInput>["mentionCallback"]
  onSubmit: (comment: string) => void | Promise<void>
}

export const Commentary = ({
  comments,
  onSubmit,
  mentionCallback,
}: CommentaryProps) => {
  const [showAll, setShowAll] = React.useState<boolean>(false)
  const [comment, setComment] = React.useState<string>("")
  const theme = useTheme()

  const [t] = useTranslation("translations", {
    keyPrefix: "components.commentary",
  })

  const handleSubmitComment = () => {
    const value = comment
    if (!value) return

    setComment("")
    onSubmit(value)
  }

  const allCommentaries = comments
    .sort((a, b) => a.order - b.order)
    .map(({ order, ...comment }) => comment)

  const commentaries = showAll ? allCommentaries : allCommentaries.slice(0, 3)

  return (
    <Card gap="xs2">
      <Typography variant="headline7" variantColor={theme.colors.neutral[30]}>
        {t("title")}
      </Typography>

      <SC.Wrapper>
        <MentionInput
          value={comment}
          placeholder={t("textareaPlaceholder")}
          handleChange={(value) => setComment(value)}
          mentionCallback={mentionCallback}
        />

        <div>
          <PillButton
            size="large"
            type="primary"
            variant="pink"
            icon="IconMessage"
            onClick={() => handleSubmitComment()}
          />
        </div>
      </SC.Wrapper>

      {commentaries.map((comment, idx) => (
        <Comment key={`comment-${comment.name}-${idx}`} {...comment} />
      ))}

      {allCommentaries.length > 3 && (
        <Box>
          <LinkButton
            variant="default"
            onClick={() => setShowAll((prev) => !prev)}
          >
            {t(showAll ? "showLessLabel" : "showAllLabel")}
            <Icons name={showAll ? "IconChevronUp" : "IconChevronDown"} />
          </LinkButton>
        </Box>
      )}
    </Card>
  )
}
