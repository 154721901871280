import { Modal, ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const TextStyled = styled(Typography)`
  text-align: center;
`

export const AccordionIconStyled = styled(ShapeIcon)`
  flex: 1 0 auto;
`

export const HelperTextSyled = styled(Typography)`
  margin: ${({ theme }) => `${theme.spacings.xs5} 0 0 ${theme.spacings.xs2}`};
`

export const StyledModalContent = styled(Modal.Content)`
  overflow: hidden;
  overflow-y: scroll;
  height: 70vh;
`
