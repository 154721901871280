import { Trans, useTranslation } from "react-i18next"
import { trpc } from "@api/client"
import dispatchToast from "@utils/dispatchToast"
import i18n from "@i18n"
import { ModalConfirmation } from "@organisms"

interface ModalRestartProps {
  isOpen: boolean
  onClose: () => void
  recognitionId: string
}

export const ModalRestart = ({
  isOpen,
  onClose,
  recognitionId,
}: ModalRestartProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "components.modals.restartRecognition",
  })
  const utils = trpc.useUtils()

  const { mutateAsync: restart, isLoading } =
    trpc.recognition.restartRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  const handleRestart = async () => {
    await restart({
      recognitionId: recognitionId,
    })
    dispatchToast({
      type: "success",
      content: t("messages.restartSuccess"),
    })
    onClose()
    utils.recognition.invalidate()
  }

  return (
    <ModalConfirmation
      isOpen={isOpen}
      onClose={onClose}
      title={t("title")}
      description={
        <Trans
          i18nKey={"components.modals.restartRecognition.description"}
          components={{
            bold: <strong />,
          }}
        />
      }
      onSubmit={handleRestart}
      submitButtonLabel={t("confirmButtonLabel")}
      isSubmiting={isLoading}
    />
  )
}
