import { RecognitionStatus } from "@customTypes"
import i18n from "@i18n"

export const RECOGNITION_STATUS_DESCRIPTIONS: Record<
  RecognitionStatus,
  string
> = {
  [RecognitionStatus.Approved]: i18n.t("recognitionStatus.approved"),
  [RecognitionStatus.Canceled]: i18n.t("recognitionStatus.canceled"),
  [RecognitionStatus.Rejected]: i18n.t("recognitionStatus.rejected"),
  [RecognitionStatus.InProgress]: i18n.t("recognitionStatus.inProgress"),
  [RecognitionStatus.NotStarted]: i18n.t("recognitionStatus.notStarted"),
}
