export const externalRoutes = {
  employeesProfile: {
    path: "/employees/:employeeId/profile",
  },
  authenticationLogin: {
    path: "/authentication/login",
  },
  home: {
    path: "/home",
  },
}
