import {
  Typography as DSTypography,
  Modal,
} from "@flash-tecnologia/hros-web-ui-v2"
import styled, { DefaultTheme } from "styled-components"

export const IconCircleContainer = styled.div`
  border-radius: 50%;
  width: 80px;
  height: 80px;

  background-color: ${({ theme }) => theme.colors.secondary[95]};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  > svg {
    width: 48px;
    height: 48px;
    color: ${({ theme }) => theme.colors.secondary[50]};
  }
`

export const GifContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  min-height: 215px;

  > img {
    width: 100%;
  }
`

export const StyledIframe = styled.iframe`
  margin-top: 10px;
  width: 100%;
  min-height: 215px;
`

export const Typography = styled(DSTypography)<{
  $mt?: keyof DefaultTheme["spacings"]
}>`
  margin-top: ${({ theme, $mt }) => ($mt ? `${theme.spacings[$mt]}` : 0)};
`

export const ModalContent = styled(Modal.Content)`
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: ${({ theme }) => theme.spacings.s};
`
