import { QueryClient } from "@tanstack/react-query"
import { createTRPCReact, httpBatchLink } from "@trpc/react-query"
import { getAccessToken } from "@flash-tecnologia/hros-web-utility"
import type { AppRouter } from "server"
import superjson from "superjson"

export const trpc = createTRPCReact<AppRouter>()
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
})

export const createTrpcClient = (selectedCompany: string) =>
  trpc.createClient({
    transformer: superjson,
    links: [
      httpBatchLink({
        headers: async () => {
          const token = await getAccessToken()
          return {
            "X-Flash-Auth": `Bearer ${token}`,
            "X-Selected-Company": selectedCompany,
          }
        },
        url: `${process.env.TRPC_BFF_URL}/trpc`,
      }),
    ],
  })
