export enum RecognitionStatus {
  NotStarted = "not-started",
  InProgress = "in-progress",
  Approved = "approved",
  Rejected = "rejected",
  Canceled = "canceled",
}

export enum RecognitionType {
  Promotion = "promotion",
  Merit = "merit",
  Transfer = "transfer",
}

export interface Role {
  from: { id: string; name: string }
  to: { id: string; name: string }
}

export interface Department {
  from: { id: string; name: string }
  to: { id: string; name: string }
}

export interface Salary {
  from: number
  to: number
}

export type Comment = {
  message: string
  authorEmployeeId: string
  sentDate: Date
  timeSince: string
  author: Person
}

export type Person = {
  id: string
  name: string
  profilePicture?: string
}

export interface RecognitionEntity {
  id: string
  employeeId: string
  companyId: string
  status: RecognitionStatus
  type: RecognitionType
  justification?: string
  updatedBy: string
  rejectReason?: string
  effectiveDate?: string
  communicationDate?: string
  role?: Role
  department?: Department
  salary?: Salary
  comments?: Comment[]
  createdAt: Date
  updatedAt: Date
  requestedBy: string
}

export interface FindRecognition extends RecognitionEntity {
  employee: Person
  manager?: Person
}

export interface FindRecognitionById extends RecognitionEntity {
  employee: Person
  requested?: Person
  manager?: Person
}

interface Change {
  key: string
  oldValue: string | number | Date | boolean
  newValue: string | number | Date | boolean
  label: string
}

export interface RecognitionHistory {
  changes: Change[]
  updatedBy: Person
  action: string
  updatedAt: string
}
