import {
  Button,
  Icons,
  Table,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import { useTranslation } from "react-i18next"
import { columns } from "./columns"
import { trpc } from "@api/client"
import { useDelayFn } from "@hooks"
import {
  Profile,
  RemoveArrayFromType,
  RouterInput,
  RouterOutput,
} from "@customTypes"
import dispatchToast from "@utils/dispatchToast"
import { RecognitionStatus, RecognitionType } from "server/src/types"
import { generatePath, useNavigate, useSearchParams } from "react-router-dom"
import { RecognitionDetails } from "@templates"
import { Action } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types"
import i18n from "@i18n"
import { internalRoutes } from "src/routes/internalRoutes"
import { Filters, FiltersAvailable } from "./Filters"
import { PendoEnum } from "@utils/pendo"
import { ModalRestart, ModalCancel } from "@templates"
import { globalContext } from "@context"

export enum TableRecognitionAvailableStatus {
  All = "all",
  NotStarted = "not-started",
  InProgress = "in-progress",
  Approved = "approved",
  Rejected = "rejected",
  Canceled = "canceled",
}

type RecognitionTableProps = {
  filterStatus: TableRecognitionAvailableStatus
}

type PaginationState = {
  pageSize: number
  pageNumber: number
}

export type RecognitionItem = RemoveArrayFromType<
  RouterOutput["recognition"]["getAllRecognitions"]["data"]
>

type Details = {
  isOpen: boolean
  recognitionId: string
}

enum AvailableActions {
  details = "details",
  edit = "edit",
  startProcess = "startProcess",
  restartProcess = "restartProcess",
  cancelProcess = "cancelProcess",
}

enum ModalAvailables {
  restart = "modal-restart",
  cancel = "modal-cancel",
}

type ActionsByStatus = Record<RecognitionStatus, Action<RecognitionItem>[]>
type ActionsByStatusPerProfile = Record<Profile, ActionsByStatus>

const paginationOptions = [
  {
    label: "10 itens",
    value: 10,
  },
  {
    label: "25 itens",
    value: 25,
  },
]

const RecognitionEmpty = () => {
  const navigate = useNavigate()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionTable",
  })

  return (
    <Button
      variant="primary"
      size="large"
      onClick={() => navigate(internalRoutes.create.path)}
    >
      {t("addNewEmptyButtonLabel")}
      <Icons name="IconUserPlus" fill="transparent" />
    </Button>
  )
}

export const RecognitionTable = ({ filterStatus }: RecognitionTableProps) => {
  const [modalDetails, setModalDetails] = React.useState<{
    open?: ModalAvailables
    data?: Record<string, any>
  }>({})
  const [filters, setFilters] = React.useState<FiltersAvailable>({})
  const [details, setDetails] = React.useState<Details | undefined>()
  const [searchInput, setSearchInput] = React.useState<string>("")
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const delayFn = useDelayFn(1000)
  const navigate = useNavigate()
  const { profile } = React.useContext(globalContext)
  const utils = trpc.useUtils()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionTable",
  })

  const { data: getAllRecogintionsResult, isFetching: isLoading } =
    trpc.recognition.getAllRecognitions.useQuery(
      {
        page: pagination.pageNumber,
        limit: pagination.pageSize,
        query: searchInput,
        ...getFilters(),
      },
      {
        retry: 0,
        refetchOnWindowFocus: false,
        onError: (err) => {
          dispatchToast({
            content:
              err.data?.userFriendlyError?.message ??
              i18n.t("error.internalServerError"),
            type: "error",
          })
        },
      },
    )

  const { mutateAsync: initiate } =
    trpc.recognition.initiateRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  React.useEffect(() => {
    const detailsParams = searchParams.get("details")
    if (detailsParams) {
      setDetails({
        isOpen: true,
        recognitionId: detailsParams,
      })

      const params = new URLSearchParams(searchParams)
      params.delete("details")
      setSearchParams(params)
    }
  }, [])

  const handleSearch = (input: string) => {
    delayFn(() => setSearchInput(input))
  }

  const actions: Record<
    AvailableActions,
    Action<RecognitionItem>
  > = React.useMemo(
    () => ({
      details: {
        label: t("actions.details"),
        icon: "IconFileDescription",
        key: "open",
        onClick: (data) => {
          setDetails({ isOpen: true, recognitionId: data.id })
        },
      },
      edit: {
        label: t("actions.edit"),
        icon: "IconPencil",
        key: "open",
        onClick: (data) => {
          navigate(
            generatePath(internalRoutes.edit.path, {
              id: data.id,
            }),
          )
        },
      },
      restartProcess: {
        label: t("actions.restartProcess"),
        icon: "IconRotate2",
        key: "open",
        onClick: async (data) => {
          setModalDetails({
            open: ModalAvailables.restart,
            data: { recognitionId: data.id },
          })
        },
      },
      cancelProcess: {
        label: t("actions.cancelProcess"),
        icon: "IconBan",
        key: "open",
        onClick: async (data) => {
          setModalDetails({
            open: ModalAvailables.cancel,
            data: { recognitionId: data.id },
          })
        },
      },
      startProcess: {
        label: t("actions.startProcess"),
        icon: "IconArrowRight",
        key: "open",
        onClick: async (data) => {
          await initiate({
            recognitionId: data.id,
          })
          dispatchToast({
            type: "success",
            content: t("actions.initiateSuccessMessage"),
          })
          utils.recognition.getAllRecognitions.invalidate()
        },
      },
    }),
    [],
  )

  const actionsByStatusPerProfile: ActionsByStatusPerProfile = React.useMemo(
    () => ({
      [Profile.admin]: {
        [RecognitionStatus.InProgress]: [
          actions.details,
          actions.cancelProcess,
        ],
        [RecognitionStatus.Approved]: [actions.details],
        [RecognitionStatus.Canceled]: [actions.details],
        [RecognitionStatus.Rejected]: [actions.details, actions.restartProcess],
        [RecognitionStatus.NotStarted]: [
          actions.details,
          actions.edit,
          actions.startProcess,
          actions.cancelProcess,
        ],
      },
      [Profile.manager]: {
        [RecognitionStatus.InProgress]: [actions.details],
        [RecognitionStatus.Approved]: [actions.details],
        [RecognitionStatus.Canceled]: [actions.details],
        [RecognitionStatus.Rejected]: [actions.details],
        [RecognitionStatus.NotStarted]: [actions.details, actions.edit],
      },
    }),
    [],
  )

  const table = tableControllers.useTableColumns<RecognitionItem>({
    options: {
      actions: (context) =>
        actionsByStatusPerProfile[profile][context.row.original.status],
    },
    columns: columns,
    data: getAllRecogintionsResult?.data ?? [],
    pagination: pagination,
    onPaginationChange: setPagination,
  })

  function getFilters(): Partial<
    RouterInput["recognition"]["getAllRecognitions"]
  > {
    const status =
      filterStatus !== TableRecognitionAvailableStatus.All
        ? (filterStatus.toString() as RecognitionStatus)
        : (filters.status as RecognitionStatus)

    return {
      createdFrom: filters.createdFrom
        ? new Date(filters.createdFrom)
        : undefined,
      createdTo: filters.createdTo ? new Date(filters.createdTo) : undefined,
      managerId: filters.managerId,
      status: status,
      type: filters.type ? (filters.type as RecognitionType) : undefined,
    }
  }

  return (
    <Table.Root style={{ width: "100%" }}>
      <div data-onboarding={PendoEnum.TABLE_SEARCH}>
        <Table.Search
          label={t("tableSearchLabel")}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <Filters
        profile={profile}
        status={filterStatus}
        handleChange={(filters) => setFilters(filters)}
      />

      <div data-onboarding={PendoEnum.TABLE}>
        <Table.Grid.Root
          empty={{
            message: t("tableEmptyLabel"),
            children: <RecognitionEmpty />,
          }}
          loading={isLoading}
        >
          <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          {table?.rows?.map((row, index) => (
            <Table.Grid.Row key={`${index}${row.id}`} row={row} />
          ))}
        </Table.Grid.Root>
      </div>

      <Table.Pagination
        count={getAllRecogintionsResult?.pagination?.totalItems ?? 0}
        onPaginationChange={({ pageSize, pageNumber }) =>
          setPagination({ ...pagination, pageSize, pageNumber })
        }
        pagination={pagination}
        pageSizeOptions={paginationOptions}
      />

      {details?.isOpen && (
        <RecognitionDetails
          recognitionId={details.recognitionId}
          open={details.isOpen}
          onClose={() => setDetails(undefined)}
        />
      )}

      {modalDetails.open === ModalAvailables.restart &&
        modalDetails.data?.recognitionId && (
          <ModalRestart
            isOpen={true}
            recognitionId={modalDetails.data.recognitionId}
            onClose={() => setModalDetails({})}
          />
        )}

      {modalDetails.open === ModalAvailables.cancel &&
        modalDetails.data?.recognitionId && (
          <ModalCancel
            isOpen={true}
            recognitionId={modalDetails.data.recognitionId}
            onClose={() => setModalDetails({})}
          />
        )}
    </Table.Root>
  )
}
