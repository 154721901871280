import { Box } from "@atoms"
import { Button } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"

type ButtonProps = React.ComponentProps<typeof Button>

type FooterButtonProps = {
  variantType: ButtonProps["variantType"]
  text: string
  onClick: () => void
  isLoading?: boolean
}

export type FooterProps = {
  buttonList: FooterButtonProps[]
}

export const Footer = ({ buttonList }: FooterProps) => {
  const theme = useTheme()
  const isAnyLoading = buttonList.some((button) => button.isLoading)

  return (
    <Box
      $px="xs"
      $py="xs2"
      $borderTop={`1px solid ${theme.colors.neutral[90]}`}
      gap="xs"
      justifyContent="flex-end"
    >
      {buttonList.map((button, idx) => (
        <Button
          key={`button_${button.text}_${idx}`}
          variant="primary"
          variantType={button.variantType}
          onClick={button.onClick}
          disabled={isAnyLoading}
          loading={button.isLoading}
        >
          {button.text}
        </Button>
      ))}
    </Box>
  )
}
