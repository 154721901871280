import { Box } from "@atoms"
import { Container } from "./styles"
import {
  Button,
  IconTypes,
  Icons,
  LinkButton,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"

type DumbButton = {
  text: string
  onClick(): void | Promise<void>
}

type SmartButton = {
  icon?: IconTypes
  iconPosition?: "left" | "right"
  disabled?: boolean
  loading?: boolean
} & DumbButton

export type FooterOptions = {
  cancelButton: DumbButton
  secondaryButton?: SmartButton
  primaryButton: SmartButton
}

interface FooterProps {
  options: FooterOptions
  className?: string
}

export const Footer = ({ options, className }: FooterProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  return (
    <Container className={className}>
      <LinkButton
        variant="neutral"
        style={{ alignSelf: "center" }}
        onClick={options.cancelButton.onClick}
      >
        {options.cancelButton.text}
      </LinkButton>

      <Box flexDirection="row" gap={"xs"}>
        {options.secondaryButton ? (
          <Button
            variant="secondary"
            onClick={options.secondaryButton.onClick}
            size={isMobile ? "small" : "medium"}
            disabled={options.secondaryButton.disabled ?? false}
            loading={options.secondaryButton.loading ?? false}
          >
            <>
              {options.secondaryButton.icon &&
              options.secondaryButton.iconPosition === "left" ? (
                <Icons name={options.secondaryButton.icon} fill="transparent" />
              ) : (
                <></>
              )}

              {options.secondaryButton.text}

              {options.secondaryButton.icon &&
              options.secondaryButton.iconPosition === "right" ? (
                <Icons name={options.secondaryButton.icon} fill="transparent" />
              ) : (
                <></>
              )}
            </>
          </Button>
        ) : (
          <></>
        )}
        <Button
          variant="primary"
          onClick={options.primaryButton.onClick}
          size={isMobile ? "small" : "medium"}
          disabled={options.primaryButton.disabled ?? false}
          loading={options.primaryButton.loading ?? false}
        >
          <>
            {options.primaryButton.icon &&
            options.primaryButton.iconPosition === "left" ? (
              <Icons name={options.primaryButton.icon} fill="transparent" />
            ) : (
              <></>
            )}

            {options.primaryButton.text}

            {options.primaryButton.icon &&
            options.primaryButton.iconPosition === "right" ? (
              <Icons name={options.primaryButton.icon} fill="transparent" />
            ) : (
              <></>
            )}
          </>
        </Button>
      </Box>
    </Container>
  )
}
