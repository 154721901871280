import { Box } from "@atoms"
import { FocusedFlow } from "@organisms"
import {
  CustomItems,
  EmployeeVerticalInformation,
  RecognitionForm,
  RecognitionFormType,
  RecognitionFormRef,
} from "@templates"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import * as yup from "yup"
import i18n from "@i18n"
import { trpc } from "@api/client"
import { currencyToNumberParser } from "@utils"
import dispatchToast from "@utils/dispatchToast"
import { useNavigate, useSearchParams } from "react-router-dom"
import { RecognitionType } from "@customTypes"
import React from "react"
import { internalRoutes } from "src/routes/internalRoutes"

type Breadcrumb = Parameters<typeof FocusedFlow.Header>[0]["breadcrumbs"]

const fieldsToSubmitByType: Record<
  RecognitionType,
  Partial<keyof RecognitionFormType>[]
> = {
  [RecognitionType.Merit]: [],
  [RecognitionType.Promotion]: ["role", "newRole"],
  [RecognitionType.Transfer]: [
    "role",
    "newRole",
    "department",
    "newDepartment",
  ],
}

const breadcrumbs: Breadcrumb = [
  {
    label: i18n.t("page.create.breadcrumbs.one"),
    route: "/recognition",
  },
  {
    label: i18n.t("page.create.breadcrumbs.two"),
  },
]

const validationSchema = yup.object({
  employee: yup.object().required(i18n.t("page.create.fieldRequired")),
  type: yup
    .mixed<RecognitionType>()
    .oneOf(Object.values(RecognitionType), i18n.t("page.create.fieldRequired"))
    .required(i18n.t("page.create.fieldRequired")),
  role: yup.object().when("type", {
    is: (value: any) =>
      [RecognitionType.Promotion, RecognitionType.Transfer].includes(value),
    then: yup.object().required(i18n.t("page.create.fieldRequired")),
  }),
  department: yup.object().when("type", {
    is: RecognitionType.Transfer,
    then: yup.object().required(i18n.t("page.create.fieldRequired")),
  }),
  salary: yup.string(),
  justification: yup.string().required(i18n.t("page.create.fieldRequired")),
  newRole: yup.object().when("type", {
    is: (value: any) =>
      [RecognitionType.Promotion, RecognitionType.Transfer].includes(value),
    then: yup.object().required(i18n.t("page.create.fieldRequired")),
  }),
  newDepartment: yup.object().when("type", {
    is: RecognitionType.Transfer,
    then: yup.object().required(i18n.t("page.create.fieldRequired")),
  }),
  newSalary: yup
    .string()
    .typeError(i18n.t("page.create.fieldRequired"))
    .min(1, i18n.t("page.create.fieldRequired"))
    .required(i18n.t("page.create.fieldRequired")),
})

const customItemsByType: Record<
  RecognitionType,
  Partial<keyof RecognitionFormType>[]
> = {
  [RecognitionType.Merit]: ["newSalary"],
  [RecognitionType.Promotion]: ["newSalary", "newRole"],
  [RecognitionType.Transfer]: ["newSalary", "newRole", "newDepartment"],
}

function getCustomItems(values: RecognitionFormType) {
  let items: CustomItems = []
  if (!values.type) return items

  const valuesByType = customItemsByType[values.type]
  if (valuesByType.includes("newRole") && values.newRole) {
    items.push({
      label: i18n.t("page.create.employeeCustomItems.newRole"),
      icon: "IconBriefcase",
      value: values.newRole?.label ?? "Não cadastrado",
      order: 0,
    })
  }
  if (valuesByType.includes("newDepartment") && values.newDepartment) {
    items.push({
      label: i18n.t("page.create.employeeCustomItems.newDepartment"),
      icon: "IconLayoutGrid",
      value: values.newDepartment?.label ?? "Não cadastrado",
      order: 1,
    })
  }
  if (valuesByType.includes("newSalary") && values.newSalary) {
    items.push({
      label: i18n.t("page.create.employeeCustomItems.newSalary"),
      icon: "IconMoneybag",
      value: values.newSalary ?? "Não cadastrado",
      order: 2,
    })
  }
  return items
}

export const CreateRecognition = () => {
  const [shouldValidateOnChange, setShouldValidateOnChange] =
    React.useState<boolean>(false)
  const [form, setForm] = React.useState<RecognitionFormRef>()

  const theme = useTheme()
  const navigate = useNavigate()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.create",
  })

  const [searchParams] = useSearchParams()

  const employeeId = searchParams.get("employeeId")
  const type = searchParams.get("type")

  const { mutateAsync: createRecognition } =
    trpc.recognition.createRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  const { data: employeeSelected } = trpc.employee.getCompanyEmployee.useQuery(
    {
      employeeId: form?.values.employee?.value ?? "",
    },
    {
      enabled: !!form?.values.employee?.value,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!form) return
        form.setValues({
          ...form.values,
          employee: {
            label: data.name,
            value: data.id,
          },
          department: data.departments?.[0]
            ? {
                label: data.departments[0].name,
                value: data.departments[0].id,
              }
            : form.values.department,
          role: data.roles?.[0]
            ? {
                label: data.roles[0].name,
                value: data.roles[0].id,
              }
            : form.values.role,
        })
      },
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    },
  )

  React.useEffect(() => {
    if (!form) return
    const { employee: formEmployee, type: formType } = form.values
    const fieldsToUpdate: Partial<RecognitionFormType> = {}

    if (!formEmployee?.value && employeeId)
      fieldsToUpdate.employee = { label: "", value: employeeId }
    if (!formType && type) fieldsToUpdate.type = type as RecognitionType

    if (Object.keys(fieldsToUpdate).length > 0) {
      form.setValues({
        ...form.values,
        ...fieldsToUpdate,
      })
    }
  }, [employeeId, type, form])

  const handleSubmit = async () => {
    if (!form) return
    setShouldValidateOnChange(true)
    const errors = await form.validateForm()
    if (Object.keys(errors)?.length > 0) {
      dispatchToast({
        content: t("fieldRequiredNotFilledError"),
        type: "error",
      })
      return
    }

    const recognitionForm: RecognitionFormType = await form.submitForm()
    if (
      !recognitionForm.employee ||
      !recognitionForm.type ||
      !recognitionForm.justification ||
      !recognitionForm.newSalary
    ) {
      dispatchToast({
        content: t("fieldRequiredNotFilledError"),
        type: "error",
      })
      return
    }

    await createRecognition({
      employeeId: recognitionForm.employee.value,
      justification: recognitionForm.justification,
      type: recognitionForm.type,
      salary: currencyToNumberParser(recognitionForm.salary ?? ""),
      newSalary: currencyToNumberParser(recognitionForm.newSalary ?? ""),
      ...fieldsToSubmitByType[recognitionForm.type].reduce(
        (acc: any, field) => {
          acc[field] = recognitionForm[field]
          return acc
        },
        {},
      ),
    })

    dispatchToast({
      content: t("recognitionCreatedMessage"),
      type: "success",
    })
    navigate(internalRoutes.home.path, {
      replace: true,
    })
  }

  return (
    <FocusedFlow.Root>
      <FocusedFlow.Header breadcrumbs={breadcrumbs} />
      <FocusedFlow.Content>
        <Box flexDirection="column" $px="s" $py="m" gap="xs">
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[20]}
          >
            {t("title")}
          </Typography>

          <Box gap="xs">
            <Box flexDirection="column" $width={"25%"} gap="xs3">
              <Typography
                variant="headline7"
                variantColor={theme.colors.secondary[50]}
              >
                {t("subtitle")}
              </Typography>
              <Typography
                variant="body3"
                variantColor={theme.colors.neutral[50]}
              >
                {t("description")}
              </Typography>
            </Box>

            <Box flexDirection="column" flex={1} gap="s">
              <RecognitionForm
                ref={(node: RecognitionFormRef) => setForm(node)}
                validateOnChange={shouldValidateOnChange}
                validationSchema={validationSchema}
              />
            </Box>

            <Box flexDirection="column" $width={"25%"}>
              {!!employeeSelected && (
                <EmployeeVerticalInformation
                  employee={{
                    ...employeeSelected,
                    roles: form?.values.role
                      ? [
                          {
                            id: form?.values.role.value,
                            name: form?.values.role.label,
                            description: "",
                          },
                        ]
                      : employeeSelected.roles,
                    departments: form?.values.department
                      ? [
                          {
                            id: form?.values.department.value,
                            name: form?.values.department.label,
                            description: "",
                          },
                        ]
                      : employeeSelected.departments,
                    salary: currencyToNumberParser(form?.values.salary ?? ""),
                  }}
                  customItems={form?.values ? getCustomItems(form.values) : []}
                />
              )}
            </Box>
          </Box>
        </Box>
      </FocusedFlow.Content>
      <FocusedFlow.Footer
        primaryButton={{
          text: t("footer.submitButtonLabel"),
          icon: "IconCheck",
          disabled: !form?.values.type || !form?.values.employee,
          onClick: handleSubmit,
        }}
        cancelButton={{
          text: t("footer.cancelButtonLabel"),
          onClick: () =>
            navigate(internalRoutes.home.path, {
              replace: true,
            }),
        }}
      ></FocusedFlow.Footer>
    </FocusedFlow.Root>
  )
}
