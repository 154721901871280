import { trpc } from "@api/client"
import { Box, Card, SelectRealTimeSearch } from "@atoms"
import { globalContext } from "@context"
import { Profile, RecognitionType } from "@customTypes"
import {
  SelectField,
  TextArea,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { HorizontalBigNumber } from "@molecules"
import {
  currencyToNumberParser,
  getPercentageComparison,
  numberMaskOption,
} from "@utils"
import { RECOGNITION_TYPE_DESCRIPTIONS } from "@utils/recognitionTypeDescriptions"
import { FormikConfig, useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"

export type RecognitionFormType = {
  employee?: { label: string; value: string }
  type?: RecognitionType
  role?: { label: string; value: string }
  department?: { label: string; value: string }
  salary?: string
  justification?: string
  newRole?: { label: string; value: string }
  newDepartment?: { label: string; value: string }
  newSalary?: string
}

export type RecognitionFormRef = ReturnType<
  typeof useFormik<RecognitionFormType>
>

type RecognitionFormProps = {
  validationSchema: FormikConfig<RecognitionFormType>["validationSchema"]
  initialValues?: RecognitionFormType
  validateOnChange?: boolean
  disableFields?: (keyof RecognitionFormType)[]
}

const MAX_SALARY_SUPPORTED = 999999999.99

export const RecognitionForm = React.forwardRef(
  (props: RecognitionFormProps, ref: React.Ref<RecognitionFormRef>) => {
    const [employeeSearch, setEmployeeSearch] = React.useState<string>("")

    const theme = useTheme()
    const { profile, user } = React.useContext(globalContext)
    const [t] = useTranslation("translations", {
      keyPrefix: "components.recognitionForm",
    })

    const formik = useFormik<RecognitionFormType>({
      initialValues: {
        employee: props.initialValues?.employee ?? undefined,
        type: props.initialValues?.type ?? undefined,
        role: props.initialValues?.role ?? undefined,
        department: props.initialValues?.department ?? undefined,
        salary: props.initialValues?.salary ?? "",
        justification: props.initialValues?.justification ?? "",
        newRole: props.initialValues?.newRole ?? undefined,
        newDepartment: props.initialValues?.newDepartment ?? undefined,
        newSalary: props.initialValues?.newSalary ?? "",
      },
      validationSchema: props.validationSchema,
      validateOnChange: props.validateOnChange,
      validateOnBlur: false,
      onSubmit: async (values): Promise<RecognitionFormType> => values,
    })

    const memoizedFormik = React.useMemo(() => formik, [formik.values])

    React.useImperativeHandle(ref, () => memoizedFormik, [memoizedFormik])

    const { data: employees, isFetching: isLoadingEmployees } =
      trpc.employee.getCompanyEmployees.useQuery(
        {
          search: employeeSearch,
          managerId: profile === Profile.manager ? user.id : undefined,
        },
        {
          enabled: employeeSearch?.length >= 3,
        },
      )

    const { data: roles, isFetching: isRolesLoading } =
      trpc.company.getRoles.useQuery(undefined, {
        enabled:
          !!formik.values.type &&
          [RecognitionType.Promotion, RecognitionType.Transfer].includes(
            formik.values.type as RecognitionType,
          ),
      })

    const { data: departments, isFetching: isDepartmentsLoading } =
      trpc.company.getDepartments.useQuery(undefined, {
        enabled:
          !!formik.values.type &&
          RecognitionType.Transfer === (formik.values.type as RecognitionType),
      })

    return (
      <>
        <Card gap="xs">
          <Box flexDirection="row" justifyContent="space-between">
            <Box flex={1} flexDirection="column">
              <Typography
                variant="headline8"
                variantColor={theme.colors.neutral[30]}
              >
                {t("content.basicInformationTitle")}
              </Typography>
              <Typography
                variant="body4"
                variantColor={theme.colors.neutral[50]}
              >
                {t("content.basicInformationDescription")}
              </Typography>
            </Box>

            <Box $width={"max-content"} gap="xs5">
              <Typography
                variant="body3"
                variantColor={theme.colors.feedback.error[40]}
              >
                *
              </Typography>
              <Typography
                variant="body3"
                variantColor={theme.colors.neutral[30]}
              >
                {t("requiredField")}
              </Typography>
            </Box>
          </Box>

          <SelectRealTimeSearch
            value={formik.values.employee}
            label={t("content.selectPersonLabel")}
            loadingOptionsLabel={t("selectLoadingLabel")}
            noOptionsLabel={t("selectNoOptionsLabel")}
            noSearchLabel={t("content.selectPersonNoSearchLabel")}
            onSearchCallback={(text) => {
              setEmployeeSearch(text ?? "")
            }}
            onSelectChange={(value) => {
              setEmployeeSearch("")
              formik.handleChange({
                target: { name: "employee", value },
              })
            }}
            options={(employees ?? []).map((employee) => ({
              label: employee.name,
              value: employee.id,
            }))}
            loading={isLoadingEmployees}
            error={!!formik.errors.employee}
            helperText={formik.errors.employee?.toString()}
            required={true}
            disabled={props.disableFields?.some(
              (field) => field === "employee",
            )}
          />

          <SelectField
            fullWidth={true}
            value={formik.values.type || null}
            label={
              <Box gap="xs5">
                {t("content.typeRecognitionLabel")}
                <Typography
                  variant="body3"
                  variantColor={theme.colors.feedback.error[40]}
                >
                  *
                </Typography>
              </Box>
            }
            onSelectChange={(e, { value }) => {
              formik.handleChange({
                target: { name: "type", value },
              })
            }}
            error={!!formik.errors.type}
            helperText={formik.errors.type?.toString()}
            required={true}
            options={Object.keys(RECOGNITION_TYPE_DESCRIPTIONS).map((key) => ({
              label: RECOGNITION_TYPE_DESCRIPTIONS[key as RecognitionType],
              value: key,
            }))}
            disabled={props.disableFields?.some((field) => field === "type")}
          />

          {[RecognitionType.Promotion, RecognitionType.Transfer].includes(
            formik.values.type as RecognitionType,
          ) && (
            <SelectField
              fullWidth={true}
              value={formik.values.role || null}
              label={
                <Box gap="xs5">
                  {t("content.roleSelectLabel")}
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.feedback.error[40]}
                  >
                    *
                  </Typography>
                </Box>
              }
              onSelectChange={(e, value) =>
                formik.handleChange({
                  target: { name: "role", value },
                })
              }
              noOptionsText={
                isRolesLoading
                  ? t("selectLoadingLabel")
                  : t("selectNoOptionsLabel")
              }
              error={!!formik.errors.role}
              helperText={formik.errors.role?.toString()}
              required={true}
              options={(roles ?? []).map((role) => ({
                label: role.name,
                value: role.id,
              }))}
              disabled={props.disableFields?.some((field) => field === "role")}
            />
          )}

          {RecognitionType.Transfer ===
            (formik.values.type as RecognitionType) && (
            <SelectField
              fullWidth={true}
              value={formik.values.department || null}
              label={
                <Box gap="xs5">
                  {t("content.departmentSelectLabel")}
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.feedback.error[40]}
                  >
                    *
                  </Typography>
                </Box>
              }
              onSelectChange={(e, value) =>
                formik.handleChange({
                  target: { name: "department", value },
                })
              }
              noOptionsText={
                isDepartmentsLoading
                  ? t("selectLoadingLabel")
                  : t("selectNoOptionsLabel")
              }
              error={!!formik.errors.department}
              helperText={formik.errors.department?.toString()}
              required={true}
              options={(departments ?? []).map((department) => ({
                label: department.name,
                value: department.id,
              }))}
              disabled={props.disableFields?.some(
                (field) => field === "department",
              )}
            />
          )}

          {!!formik.values.type && (
            <TextField
              label={t("content.salaryLabel")}
              placeholder={t("content.salaryLabel")}
              value={formik.values.salary}
              imaskProps={numberMaskOption({ max: MAX_SALARY_SUPPORTED })}
              onChange={(e) => {
                formik.handleChange({
                  target: {
                    id: "salary",
                    value: e.target.value,
                  },
                })
              }}
              error={!!formik.errors.salary}
              helperText={formik.errors.salary?.toString()}
              disabled={props.disableFields?.some(
                (field) => field === "salary",
              )}
            />
          )}

          <TextArea
            value={formik.values.justification}
            placeholder={t("content.justifyRecognitionLabel")}
            errorMessage={formik.errors.justification?.toString()}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              formik.handleChange({
                target: { name: "justification", value: e.target.value },
              })
            }}
            required={true}
            maxLength={3000}
            disabled={props.disableFields?.some(
              (field) => field === "justification",
            )}
          />
        </Card>

        {!!formik.values.type && (
          <Card gap="xs">
            <Box flexDirection="row" justifyContent="space-between">
              <Box flex={1} flexDirection="column">
                <Typography
                  variant="headline8"
                  variantColor={theme.colors.neutral[30]}
                >
                  {t("recognitionContent.title")}
                </Typography>
                <Typography
                  variant="body4"
                  variantColor={theme.colors.neutral[50]}
                >
                  {t("recognitionContent.description")}
                </Typography>
              </Box>

              <Box $width={"max-content"} gap="xs5">
                <Typography
                  variant="body3"
                  variantColor={theme.colors.feedback.error[40]}
                >
                  *
                </Typography>
                <Typography
                  variant="body3"
                  variantColor={theme.colors.neutral[30]}
                >
                  {t("requiredField")}
                </Typography>
              </Box>
            </Box>

            {[RecognitionType.Promotion, RecognitionType.Transfer].includes(
              formik.values.type as RecognitionType,
            ) && (
              <SelectField
                fullWidth={true}
                value={formik.values.newRole || null}
                label={
                  <Box gap="xs5">
                    {t("recognitionContent.newRoleLabel")}
                    <Typography
                      variant="body3"
                      variantColor={theme.colors.feedback.error[40]}
                    >
                      *
                    </Typography>
                  </Box>
                }
                onSelectChange={(e, value) =>
                  formik.handleChange({
                    target: { name: "newRole", value },
                  })
                }
                noOptionsText={
                  isRolesLoading
                    ? t("selectLoadingLabel")
                    : t("selectNoOptionsLabel")
                }
                error={!!formik.errors.newRole}
                helperText={formik.errors.newRole?.toString()}
                required={true}
                options={(roles ?? []).map((role) => ({
                  label: role.name,
                  value: role.id,
                }))}
                disabled={props.disableFields?.some(
                  (field) => field === "newRole",
                )}
              />
            )}

            {RecognitionType.Transfer ===
              (formik.values.type as RecognitionType) && (
              <SelectField
                fullWidth={true}
                value={formik.values.newDepartment || null}
                label={
                  <Box gap="xs5">
                    {t("recognitionContent.newDepartmentLabel")}
                    <Typography
                      variant="body3"
                      variantColor={theme.colors.feedback.error[40]}
                    >
                      *
                    </Typography>
                  </Box>
                }
                onSelectChange={(e, value) =>
                  formik.handleChange({
                    target: { name: "newDepartment", value },
                  })
                }
                noOptionsText={
                  isDepartmentsLoading
                    ? t("selectLoadingLabel")
                    : t("selectNoOptionsLabel")
                }
                error={!!formik.errors.newDepartment}
                helperText={formik.errors.newDepartment?.toString()}
                required={true}
                options={(departments ?? []).map((department) => ({
                  label: department.name,
                  value: department.id,
                }))}
                disabled={props.disableFields?.some(
                  (field) => field === "newDepartment",
                )}
              />
            )}

            <TextField
              label={
                <Box gap="xs5">
                  {t("recognitionContent.newSalaryLabel")}
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.feedback.error[40]}
                  >
                    *
                  </Typography>
                </Box>
              }
              placeholder={t("recognitionContent.newSalaryLabel")}
              imaskProps={numberMaskOption({ max: MAX_SALARY_SUPPORTED })}
              value={formik.values.newSalary}
              onChange={(e) => {
                formik.handleChange({
                  target: {
                    id: "newSalary",
                    value: e.target.value,
                  },
                })
              }}
              error={!!formik.errors.newSalary}
              helperText={formik.errors.newSalary?.toString()}
              disabled={props.disableFields?.some(
                (field) => field === "newSalary",
              )}
            />

            <HorizontalBigNumber
              icon="IconCoin"
              iconColors={{
                primary: theme.colors.feedback.success[40],
                secondary: theme.colors.feedback.success[90],
              }}
              title={t("recognitionContent.bigNumberLabel")}
              textColor={theme.colors.feedback.success[40]}
              value={getPercentageComparison(
                currencyToNumberParser(formik.values.salary ?? ""),
                currencyToNumberParser(formik.values.newSalary ?? ""),
              )}
            />
          </Card>
        )}
      </>
    )
  },
)
