import styled from "styled-components"
import { MentionsInput } from "react-mentions"

export const StyledMentionsInput = styled(MentionsInput)`
  width: 100%;
  height: ${({ theme }) => theme.spacings.l};
  margin-right: ${({ theme }) => theme.spacings.xs2};

  textarea {
    width: 100%;
    background-color: transparent;
    border: ${({ theme }) => theme.borders.width.s} solid
      ${({ theme }) => theme.colors.neutral[70]};
    border-radius: ${({ theme }) => theme.borders.radius.m};
    padding: ${({ theme }) => theme.spacings.xs2};
    overflow: auto !important;

    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      background: transparent;
      width: ${({ theme }) => theme.spacings.xs4};
      height: ${({ theme }) => theme.spacings.m};
    }

    :focus {
      outline: none !important;
      border: ${({ theme }) => theme.borders.width.s} solid
        ${({ theme }) => theme.colors.primary};
    }
  }
`
