import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Grid = styled.div<{ itemsQty: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ itemsQty }) =>
    Array(itemsQty).fill("1fr").join(" ")};
  gap: ${({ theme }) => theme.spacings.xs2};
  width: 600px;
`

export const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  width: 680px;
`

export const PinkSkeleton = styled(Skeleton)`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`
