import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const RecognitionFromStyled = styled(Typography)`
  flex: 1;
  text-decoration: line-through;
`

export const RecognitionToStyled = styled(Typography)`
  flex: 1;
`
