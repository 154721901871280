import { Box, BoxProps } from "@atoms"
import { useTheme } from "styled-components"

type Props = {
  children?: React.ReactNode
} & BoxProps

export const Card = ({ children, ...rest }: Props) => {
  const theme = useTheme()

  return (
    <Box
      flexDirection="column"
      $px="s"
      $py="s"
      $border={`1px solid ${theme.colors.neutral[90]}`}
      $borderRadius={"12"}
      {...rest}
    >
      {children}
    </Box>
  )
}
