import { Box } from "@atoms"
import {
  Button,
  LinkButton,
  Modal,
  ShapeIcon,
  TextArea,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { TextAreaWrapperStyled, TextStyled } from "./styles"
import { useRejectRecognition } from "./useRejectRecognition"

interface ModalRejectProps {
  isOpen?: boolean
  onRejectSuccess: () => void
  onClose: () => void
  recognitionId: string
}

export const ModalReject = ({
  isOpen = false,
  onRejectSuccess,
  onClose,
  recognitionId,
}: ModalRejectProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "components.modals.rejectRecognition",
  })
  const theme = useTheme()

  const {
    isLoading,
    isRejectButtonDisabled,
    onRejectReasonChange,
    onRejectReasonBlur,
    onSubmit,
    rejectReasonErrorText,
  } = useRejectRecognition(recognitionId, onRejectSuccess)

  return (
    <Modal.Root open={isOpen} onClose={onClose} size="xs">
      <>
        <Modal.Content>
          <Box flexDirection="column" gap="xs2" alignItems="center">
            <ShapeIcon
              name={"IconFlag"}
              size={64}
              color={theme.colors.feedback.error[40]}
              variant={"error"}
            />
            <Typography
              variant="body3"
              weight={700}
              variantColor={theme.colors.feedback.error[40]}
            >
              {t("iconLabel")}
            </Typography>
            <TextStyled
              variant="headline6"
              weight={700}
              variantColor={theme.colors.neutral[20]}
            >
              {t("title")}
            </TextStyled>
            <TextStyled
              variant="body3"
              weight={400}
              variantColor={theme.colors.neutral[50]}
            >
              {t("description")}
            </TextStyled>
            <TextAreaWrapperStyled>
              <TextArea
                placeholder={t("form.fields.rejectReason.label")}
                errorMessage={rejectReasonErrorText}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  onRejectReasonChange(e.target.value)
                }}
                onBlur={onRejectReasonBlur}
                maxLength={3000}
              />
            </TextAreaWrapperStyled>
          </Box>
        </Modal.Content>
        <Modal.Footer>
          <Box
            alignItems="center"
            gap="xs2"
            justifyContent="space-between"
            $width={"100%"}
          >
            <div>
              <LinkButton variant="default" onClick={onClose}>
                {t("footer.buttons.cancel")}
              </LinkButton>
            </div>
            <Button
              variant="primary"
              loading={isLoading}
              disabled={isRejectButtonDisabled}
              onClick={() => onSubmit()}
            >
              {t("footer.buttons.reject")}
            </Button>
          </Box>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}
