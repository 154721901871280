import { Profile } from "@customTypes"
import { internalRoutes } from "./internalRoutes"

export const admin_slug = "people_flows_view_edit_recognitions"
export const super_admin_slug = "*"

export const adminSlugs = [admin_slug, super_admin_slug]

type ProfilePermissions = Record<Profile, { routes: string[] }>

export const profilePermissions: ProfilePermissions = {
  [Profile.admin]: {
    routes: Object.values(internalRoutes).map((route) => route.path),
  },
  [Profile.manager]: {
    routes: Object.values(internalRoutes).map((route) => route.path),
  },
}
