import { TimeTrackStyled, TimeTrackWrapperStyled } from "./styles"

export type TimeTrackProps = {
  highlighted?: boolean
  isLastEvent?: boolean
}

export const TimeTrack = ({ highlighted, isLastEvent }: TimeTrackProps) => {
  return (
    <TimeTrackWrapperStyled justifyContent="center" isLastEvent={isLastEvent}>
      <TimeTrackStyled highlighted={highlighted} isLastEvent={isLastEvent} />
    </TimeTrackWrapperStyled>
  )
}
