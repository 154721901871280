import React from "react"
import ReactDOM from "react-dom"
import singleSpaReact from "single-spa-react"
import Root from "./root.component"
import ErrorBoundary from "@utils/ErrorBoundary"

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundaryClass: ErrorBoundary,
})

export const { bootstrap, mount, unmount } = lifecycles
