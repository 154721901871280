import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const TextStyled = styled(Typography)`
  text-align: center;
`

export const TextAreaWrapperStyled = styled.div`
  width: 100%;
`
