import React from "react"
import { AppProps } from "single-spa"

type Props = React.PropsWithChildren<AppProps>

export interface State {
  hasError: boolean
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {
    this.setState({
      hasError: true,
    })
  }

  render() {
    if (this.state.hasError) {
      return <>ERROR</>
    }

    return this.props.children
  }
}
