import { Box } from "@atoms"
import {
  Button,
  Icons,
  PillButton,
  Tab,
  TabItens,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { RecognitionTable, TableRecognitionAvailableStatus } from "./Table"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import i18n from "@i18n"
import { useNavigate } from "react-router-dom"
import { internalRoutes } from "src/routes/internalRoutes"
import { PendoEnum } from "@utils/pendo"

const tabsLabel: Record<TableRecognitionAvailableStatus, string> = {
  [TableRecognitionAvailableStatus.All]: i18n.t("page.home.tabs.all"),
  [TableRecognitionAvailableStatus.InProgress]: i18n.t(
    "page.home.tabs.inProgress",
  ),
  [TableRecognitionAvailableStatus.NotStarted]: i18n.t(
    "page.home.tabs.notStarted",
  ),
  [TableRecognitionAvailableStatus.Approved]: i18n.t("page.home.tabs.approved"),
  [TableRecognitionAvailableStatus.Rejected]: i18n.t("page.home.tabs.rejected"),
  [TableRecognitionAvailableStatus.Canceled]: i18n.t("page.home.tabs.canceled"),
}

export const Home = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.home",
  })

  const tabs: TabItens[] = Object.values(TableRecognitionAvailableStatus).map(
    (status, idx) => ({
      label: tabsLabel[status],
      component: (
        <RecognitionTable key={`${status}-${idx}`} filterStatus={status} />
      ),
    }),
  )

  return (
    <Box $px="s" $py="s" flexDirection="column">
      <Box flexDirection="row" justifyContent="space-between" $width={"100%"}>
        <Typography variant="headline6" variantColor={theme.colors.neutral[20]}>
          {t("title")}
        </Typography>

        <Box flexDirection="row" gap="xs2">
          {false && ( // to be implemented
            <>
              <PillButton
                size="small"
                variant="default"
                icon="IconDotsVertical"
              />
              <PillButton size="small" variant="default" icon="IconSettings" />
            </>
          )}

          <Button
            variant="primary"
            onClick={() => navigate(internalRoutes.create.path)}
          >
            {t("addButtonLabel")}
            <Icons name="IconPlus" fill="transparent"></Icons>
          </Button>
        </Box>
      </Box>

      <Box
        flexDirection="column"
        alignItems="flex-start"
        $width={"100%"}
        $mt="m"
      >
        <Tab id={PendoEnum.TABLE_STATUS_TABS} tabItens={tabs} />
      </Box>
    </Box>
  )
}
