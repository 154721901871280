import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server"
import type { AppRouter } from "server"

export type RouterInput = inferRouterInputs<AppRouter>
export type RouterOutput = inferRouterOutputs<AppRouter>

export enum Profile {
  manager = "manager",
  admin = "admin",
}

export * from "server/src/types"

export * from "./helperTypes"
