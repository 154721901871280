import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs1};
`
