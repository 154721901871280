import { Box } from "@atoms"
import { RecognitionStatus, RouterOutput } from "@customTypes"
import { Divider, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { HorizontalBigNumber } from "@molecules"
import { getPercentageComparison, stringToCurrencyParser } from "@utils"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { RecognitionApprovalSection } from "./RecognitionApprovalSection"
import { RecognitionRejectionSection } from "./RecognitionRejectionSection"
import { RecognitionCommentary } from "./RecognitionCommentary"

type OverviewTabProps = Partial<
  Pick<
    RouterOutput["recognition"]["getRecognitionById"],
    | "id"
    | "communicationDate"
    | "department"
    | "effectiveDate"
    | "justification"
    | "manager"
    | "rejectReason"
    | "requested"
    | "role"
    | "salary"
    | "status"
    | "comments"
  >
>

export const OverviewTab = ({
  id,
  communicationDate,
  department,
  effectiveDate,
  justification,
  manager,
  rejectReason,
  requested,
  role,
  salary,
  status,
  comments,
}: OverviewTabProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionDetails",
  })

  return (
    <>
      <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
        {t("content.title")}
      </Typography>

      <Box flexDirection="row" gap="xs">
        {requested?.name && (
          <Box flexDirection="column">
            <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
              {t("content.requestedBy")}
            </Typography>
            <Tag variant="pink">{requested.name}</Tag>
          </Box>
        )}

        {manager?.name && (
          <Box flexDirection="column">
            <Typography
              variant="caption"
              variantColor={theme.colors.neutral[50]}
            >
              {t("content.manager")}
            </Typography>
            <Tag variant="pink">{manager.name}</Tag>
          </Box>
        )}
      </Box>

      {role && (
        <Box flexDirection="column">
          <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
            {t("content.fromRole")}
          </Typography>
          <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
            {role.from.name}
          </Typography>
        </Box>
      )}

      {department && (
        <Box flexDirection="column">
          <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
            {t("content.fromDepartment")}
          </Typography>
          <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
            {department.from.name}
          </Typography>
        </Box>
      )}

      {salary && (
        <Box flexDirection="column">
          <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
            {t("content.fromSalary")}
          </Typography>
          <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
            {stringToCurrencyParser(salary.from)}
          </Typography>
        </Box>
      )}

      <Box flexDirection="column">
        <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
          {t("content.justification")}
        </Typography>
        <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
          {justification}
        </Typography>
      </Box>

      <Divider orientation="horizontal" />

      <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
        {t("content.recognitionTitle")}
      </Typography>

      {role && (
        <Box flexDirection="column">
          <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
            {t("content.toRole")}
          </Typography>
          <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
            {role.to.name}
          </Typography>
        </Box>
      )}

      {department && (
        <Box flexDirection="column">
          <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
            {t("content.toDepartment")}
          </Typography>
          <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
            {department.to.name}
          </Typography>
        </Box>
      )}

      {salary && (
        <Box flexDirection="column">
          <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
            {t("content.toSalary")}
          </Typography>
          <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
            {stringToCurrencyParser(salary?.to)}
          </Typography>
        </Box>
      )}

      {salary && (
        <HorizontalBigNumber
          icon="IconCoin"
          iconColors={{
            primary: theme.colors.feedback.success[40],
            secondary: theme.colors.feedback.success[90],
          }}
          title={t("content.bigNumberLabel")}
          textColor={theme.colors.feedback.success[40]}
          value={getPercentageComparison(salary.from, salary.to)}
        />
      )}

      {status === RecognitionStatus.Approved && (
        <RecognitionApprovalSection
          communicationDate={communicationDate ?? ""}
          effectiveDate={effectiveDate ?? ""}
        />
      )}

      {status === RecognitionStatus.Rejected && (
        <RecognitionRejectionSection rejectReason={rejectReason ?? ""} />
      )}

      {!!id && (
        <RecognitionCommentary
          recognitionId={id}
          comments={comments ?? []}
        />
      )}
    </>
  )
}
