import {
  RouterProvider,
  createBrowserRouter,
  RouteObject,
} from "react-router-dom"
import { RoutesGuard } from "./routesGuard"
import { routesConfig, RoutesConfig } from "./routesConfig"
import { FeaturesNotification } from "@pages"

function createRouteObjects(routes: RoutesConfig): RouteObject[] {
  return Object.values(routes).map((routeObject) => {
    const { path, element, children } = routeObject
    return {
      path,
      element,
      children: children ? createRouteObjects(children) : undefined,
    }
  })
}

const router = createBrowserRouter([
  {
    element: <RoutesGuard />,
    children: [
      {
        element: <FeaturesNotification />,
        children: createRouteObjects(routesConfig),
      },
    ],
  },
])

const AppRouter = () => {
  return <RouterProvider router={router} />
}

export default AppRouter
