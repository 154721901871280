import {
  Button,
  Icons,
  LinkButton,
  Modal,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import React from "react"
import { Box } from "@atoms"
import * as SC from "./styles"
import { useTranslation } from "react-i18next"
import { GifContent } from "./Types/Gif"
import { YoutubeContent } from "./Types/Youtube"

export enum FeatureNotificationTypesAvailable {
  gif = "gif",
  youtube = "youtube",
}

export type FeatureContent = {
  title: string
  description: string
  type: FeatureNotificationTypesAvailable
  value: string
}

export type FeatureNotificationType = {
  identifier: string
  modal: FeatureContent
  isLive: boolean
  order: number
}

export type NotificationFeatureModalProps = {
  features: FeatureNotificationType[]
  handleClose: () => void
}

function getContent(feature: FeatureNotificationType) {
  switch (feature.modal.type) {
    case FeatureNotificationTypesAvailable.gif:
      return <GifContent value={feature.modal.value} />
    case FeatureNotificationTypesAvailable.youtube:
      return <YoutubeContent value={feature.modal.value} />
    default:
      return <></>
  }
}

export const NotificationFeatureModal = ({
  features,
  handleClose,
}: NotificationFeatureModalProps) => {
  const [page, setPage] = React.useState<number>(0)

  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.featureNotification",
  })

  const onlyOneFeature = features?.length === 1
  const isLastPage = features?.length === page + 1

  const handleSubmit = () => {
    if (onlyOneFeature || isLastPage) {
      handleClose()
      return
    }

    setPage((prev) => {
      return prev + 1
    })
  }

  const handleBack = () => {
    if (!onlyOneFeature && page > 0) {
      setPage((prev) => {
        return prev - 1
      })
      return
    }

    handleClose()
  }

  if (features?.length === 0) return <></>

  const feature = features[page]

  return (
    <Modal.Root open={features?.length > 0} onClose={handleClose} size="xs">
      <>
        <SC.ModalContent>
          <Box
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            $textAlign="center"
          >
            <SC.IconCircleContainer>
              <Icons name="IconSparkles" fill="transparent" />
            </SC.IconCircleContainer>

            <SC.Typography
              variant="body3"
              variantColor={theme.colors.secondary[50]}
              weight={700}
              $mt={"xs1"}
            >
              {t("newLabel")}
            </SC.Typography>

            <SC.Typography
              variant="headline6"
              variantColor={theme.colors.neutral[20]}
              $mt={"xs4"}
            >
              {feature.modal.title}
            </SC.Typography>

            <SC.Typography
              variant="body3"
              variantColor={theme.colors.neutral[50]}
              $mt={"xs2"}
            >
              {feature.modal.description}
            </SC.Typography>

            {getContent(feature)}
          </Box>
        </SC.ModalContent>
        <Modal.Footer>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            $width={"100%"}
          >
            <Box alignItems="center">
              <LinkButton variant="neutral" onClick={handleBack}>
                {onlyOneFeature || page === 0
                  ? t("laterButtonLabel")
                  : t("backButtonLabel")}
              </LinkButton>
            </Box>

            <Button size="large" variant="primary" onClick={handleSubmit}>
              {isLastPage ? t("startUseButtonLabel") : t("nextButtonLabel")}
            </Button>
          </Box>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}
