import { Footer as FooterComponent } from "@molecules"

type CancelButton = Parameters<
  typeof FooterComponent
>[0]["options"]["cancelButton"]

type PrimaryButton = Omit<
  Parameters<typeof FooterComponent>[0]["options"]["primaryButton"],
  "iconPosition"
>

type Props = {
  cancelButton: CancelButton
  primaryButton: PrimaryButton
}

export const Footer = ({ cancelButton, primaryButton }: Props) => {
  return (
    <FooterComponent
      options={{
        cancelButton,
        primaryButton: {
          ...primaryButton,
          iconPosition: "right",
        },
      }}
    />
  )
}
