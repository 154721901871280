import {
  Icons,
  LinkButton,
  PillButton,
  Typography,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Container, StyledBreadcrumbs, StyledStepper } from "./styles"
import { useNavigate } from "react-router-dom"
import React from "react"
import { useTheme } from "styled-components"
import { Box } from "@atoms"

export type HeaderBreadcrumb = {
  label: string
  route?: string
}

interface HeaderProps {
  breadcrumbs: HeaderBreadcrumb[]
  steps?: string[]
  currentStep?: number
  className?: string
}

export const Header = ({
  breadcrumbs,
  currentStep = 0,
  steps = [],
  className,
}: HeaderProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)")
  const navigate = useNavigate()
  const theme = useTheme()

  const breadcrumbComponents = React.useMemo(
    () =>
      breadcrumbs.map(({ label, route }, idx) => {
        if (route) {
          return (
            <LinkButton
              key={`key-${label}-${idx}`}
              variant="neutral"
              onClick={() => navigate(route)}
            >
              {label}
            </LinkButton>
          )
        }

        return (
          <Typography
            variant="body4"
            weight={700}
            variantColor={theme.colors.neutral[40]}
          >
            {label}
          </Typography>
        )
      }),
    [breadcrumbs, navigate, theme],
  )

  return (
    <Container className={className}>
      <Box alignItems="center" $mr="xs1">
        <PillButton
          variant="default"
          size={"small"}
          icon="IconMenu2"
          onClick={() => navigate(-1)}
        />

        <StyledBreadcrumbs
          separator={<Icons name="IconChevronRight" fill="transparent" />}
          breadcrumbs={breadcrumbComponents}
        />
      </Box>

      {!isMobile && steps?.length > 0 ? (
        <StyledStepper steps={steps} activeStep={currentStep} />
      ) : (
        <></>
      )}
    </Container>
  )
}
