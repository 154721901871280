import { RecognitionStatus } from "@customTypes"
import i18n from "@i18n"
import { Filter } from "."
import { RECOGNITION_STATUS_DESCRIPTIONS } from "@utils/recognitionStatusDescriptions"

const statusOptions = Object.values(RecognitionStatus).map((value) => ({
  label: RECOGNITION_STATUS_DESCRIPTIONS[value],
  value: value,
}))

export const statusFilterName = "status"

export const statusFilter: Filter = {
  label: i18n.t("components.recognitionTable.filters.status.label"),
  name: statusFilterName,
  options: statusOptions,
  type: "radio",
}
