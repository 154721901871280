import React from "react"
import { Outlet, Navigate, useLocation, matchPath } from "react-router-dom"
import {
  getFromLS,
  useContractModules,
} from "@flash-tecnologia/hros-web-utility"
import { useAuth } from "@flash-hros/auth-helper"
import { profilePermissions } from "./permission"
import { globalContext } from "@context"
import { externalRoutes } from "./externalRoutes"

export const RoutesGuard = ({ children }: { children?: JSX.Element }) => {
  const location = useLocation()

  const isLoggedIn = getFromLS("hrosAccessToken")
  const { profile } = React.useContext(globalContext)

  const contractStatus = useAuth.getState().contractStatus || null
  const peopleContractModules = useContractModules()
  const hasModule = peopleContractModules?.modules?.includes("flows")

  const routes = profilePermissions[profile].routes
  const isRouteAllowed = routes.some((c) =>
    matchPath({ path: c, end: true }, location.pathname),
  )

  if (!isLoggedIn) {
    return <Navigate to={externalRoutes.authenticationLogin.path} />
  }

  if (!hasModule) {
    return <Navigate to={externalRoutes.home.path} />
  }

  if (!isRouteAllowed) {
    return <Navigate to={externalRoutes.home.path} />
  }

  if (contractStatus?.url) {
    return <Navigate to={contractStatus?.url} state={{ from: location }} />
  }

  return children ?? <Outlet />
}
