import { Event } from "./Event"
import { Root } from "./Root"

export interface VerticalTimeLineItemProps {
  highlighted?: boolean
  isLastItem?: boolean
}

const VerticalTimeLine = {
  Root,
  Event,
}

export default VerticalTimeLine
