import React from "react"
import { trpc } from "@api/client"
import { OptionsTagFilter, TagFilter } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { CustomFieldProps } from "."

export const managerFilterName = "managerId"

export const ManagerFilter = ({ handleChange }: CustomFieldProps) => {
  const [selectedManagers, setSelectedManagers] = React.useState<
    OptionsTagFilter["value"][]
  >([])

  const { data: managers, isLoading } =
    trpc.recognition.getManagersList.useQuery()

  const options: OptionsTagFilter[] = (managers ?? []).map((manager) => ({
    label: manager.name,
    value: manager.id,
  }))

  const handleApply = (value: (string | number)[]) => {
    const parsedValue = value?.[0]?.toString()
    setSelectedManagers([parsedValue])
    handleChange(managerFilterName, parsedValue)
  }

  const handleClear = () => {
    setSelectedManagers([])
    handleChange(managerFilterName)
  }

  return (
    <TagFilter
      hasLeftIcon={false}
      key={managerFilterName}
      onApply={handleApply}
      onClear={handleClear}
      loading={isLoading}
      options={options}
      optionIconType={"radio"}
      selectedOptions={selectedManagers}
      variant={isLoading ? "disabled" : "tertiary"}
      filterLabel={
        i18n.t("components.recognitionTable.filters.managers.label") as string
      }
    />
  )
}
