import AppRouter from "./routes"
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2"
import { trpc, createTrpcClient, queryClient } from "./api/client"
import { QueryClientProvider } from "@tanstack/react-query"
import { GlobalContext } from "@context"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"
import "../src/i18n"

export default function Root() {
  const { selectedCompany } = useSelectedCompany()
  return (
    <trpc.Provider
      client={createTrpcClient(selectedCompany.id)}
      queryClient={queryClient}
    >
      <QueryClientProvider client={queryClient}>
        <GlobalContext>
          <ThemeProvider>
            <AppRouter />
          </ThemeProvider>
        </GlobalContext>
      </QueryClientProvider>
    </trpc.Provider>
  )
}
