import { Box } from "@atoms"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

export const ContentSkeleton = () => {
  return (
    <Box flexDirection="column" $py="xs" $px="m" gap="xs">
      <Skeleton variant="rounded" height={40} width={600} />
      <Skeleton variant="rounded" height={24} width={180} />
      <Box flexDirection="row" gap="xs">
        <Skeleton variant="rounded" height={50} width={100} />
        <Skeleton variant="rounded" height={50} width={100} />
      </Box>
      <Skeleton variant="rounded" height={40} width={150} />
      <Skeleton variant="rounded" height={40} width={125} />
      <Skeleton variant="rounded" height={40} width={100} />
      <Skeleton variant="rounded" height={40} width={100} />
      <Skeleton variant="rounded" height={64} width={250} />

      <Skeleton variant="rounded" height={1} width={"100%"} />

      <Skeleton variant="rounded" height={24} width={280} />
      <Skeleton variant="rounded" height={40} width={150} />
      <Skeleton variant="rounded" height={40} width={90} />
      <Skeleton variant="rounded" height={124} width={"100%"} />
    </Box>
  )
}
