import { trpc } from "@api/client"
import { VerticalInformation } from "@organisms"
import { stringToCurrencyParser } from "@utils"
import { useTranslation } from "react-i18next"
import { generatePath } from "react-router-dom"
import { externalRoutes } from "src/routes/externalRoutes"
import { RouterOutput } from "@customTypes"

type CompanyEmployeeWithSalary =
  RouterOutput["employee"]["getCompanyEmployee"] & {
    salary?: number
  }

type Items = React.ComponentProps<typeof VerticalInformation>["items"]
export type CustomItems = Items

type EmployeeVerticalInformationProps = {
  employee: CompanyEmployeeWithSalary
  customItems?: CustomItems
}

export const EmployeeVerticalInformation = ({
  employee,
  customItems = [],
}: EmployeeVerticalInformationProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "components.employeeVerticalInformation",
  })

  const { data: manager, isFetching: isFetchingManager } =
    trpc.employee.getCompanyEmployee.useQuery(
      {
        employeeId: employee.managerId ?? "",
      },
      {
        enabled: !!employee.managerId,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    )

  const getItems = (): Items => {
    let items: Items = [
      {
        label: t("items.fullName"),
        icon: "IconUser",
        value: employee.name,
        order: 0,
      },
      {
        label: t("items.documentNumber"),
        icon: "IconId",
        value: employee.documentNumber,
        order: 1,
      },
      {
        label: t("items.email"),
        icon: "IconMail",
        value: employee.corporateEmail ?? employee.email ?? "",
        order: 3,
      },
    ]

    if (employee.managerId) {
      items.push({
        label: t("items.manager"),
        icon: "IconUserPlus",
        value: manager?.name ?? "",
        loading: isFetchingManager,
        order: 2,
      })
    }

    if (employee.salary) {
      items.push({
        label: t("items.salary"),
        icon: "IconMoneybag",
        value: stringToCurrencyParser(employee.salary),
        order: 6,
      })
    }

    if ((employee.roles ?? []).length > 0) {
      items.push({
        label: t("items.role"),
        icon: "IconBriefcase",
        value: employee.roles?.[0].name ?? "",
        order: 4,
      })
    }

    if ((employee.departments ?? []).length > 0) {
      items.push({
        label: t("items.department"),
        icon: "IconLayoutGrid",
        value: employee.departments?.[0].name ?? "",
        order: 5,
      })
    }

    return items.filter((item) => !!item.value)
  }

  return (
    <VerticalInformation
      name={employee?.name}
      profilePicture={employee?.profilePicture}
      profileButton={{
        text: t("buttonRedirectLabel"),
        callback: () => {
          const path = generatePath(externalRoutes.employeesProfile.path, {
            employeeId: employee.id,
          })
          window.open(`${window.location.origin}${path}`, "_blank")
        },
        icon: "IconFileDescription",
      }}
      items={getItems()}
      subItems={customItems}
    />
  )
}
