import { Box, Clipboard } from "@atoms"
import { Icons, IconTypes, Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { StyledTypography, Wrapper } from "./styles"

type ItemMenuProps = {
  value: string
  canCopy?: boolean
  label: string
  icon?: IconTypes
  tagValue?: string
  loading?: boolean
}

export const ItemMenu = ({
  icon,
  label,
  value,
  canCopy = false,
  loading = false,
}: ItemMenuProps) => {
  const theme = useTheme()

  return (
    <Box alignItems="center" gap="xs2">
      {icon && (
        <Box $width={"max-content"}>
          <Icons name={icon} fill="transparent" />
        </Box>
      )}

      <Wrapper>
        <StyledTypography
          variant="caption"
          weight={700}
          variantColor={theme.colors.neutral[50]}
        >
          {label}
        </StyledTypography>

        {loading ? (
          <Skeleton variant="rectangular" width={"100%"} height={"20px"} />
        ) : (
          <Box alignItems="center" $width={"100%"}>
            <StyledTypography
              variant="body3"
              weight={600}
              variantColor={theme.colors.neutral[40]}
            >
              {value}
            </StyledTypography>
            {!!canCopy && <Clipboard value={value} />}
          </Box>
        )}
      </Wrapper>
    </Box>
  )
}
