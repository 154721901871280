export enum PendoEnum {
  TABLE = "people-recognition-table",
  TABLE_SEARCH = "people-recognition-table-search-input",
  TABLE_STATUS_TABS = "people-recognition-table-status-tabs",
}

export function replacePendoVariable(
  enumerator: PendoEnum,
  variables: { name: string; value: string }[],
) {
  return variables.reduce(
    (acc: string, currValue: { name: string; value: string }) => {
      return acc.replace(currValue.name, currValue.value)
    },
    enumerator.toString(),
  )
}
