import React, { ReactElement } from "react"
import {
  getAccessToken,
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility"
import { decodeJwt } from "@utils"
import { trpc } from "@api/client"
import { Profile, RouterOutput } from "@customTypes"
import dispatchToast from "@utils/dispatchToast"
import { Box } from "@atoms"
import { Spinner } from "@flash-tecnologia/hros-web-ui-v2"
import { adminSlugs } from "src/routes/permission"

type User = RouterOutput["employee"]["getUser"]

interface JwtPayload {
  employeeId: string
  economicGroupId: string
}

export interface IGlobalContext {
  employeeId?: string
  companyId?: string
  economicGroupId?: string
  user: User
  profile: Profile
}

const globalContext = React.createContext<IGlobalContext>({} as IGlobalContext)

const GlobalContext = (props: { children: ReactElement }) => {
  const [tokenData, setTokenData] = React.useState<JwtPayload>()

  const { selectedCompany } = useSelectedCompany()
  const allPermissions = usePermissions()

  const { data: user } = trpc.employee.getUser.useQuery(
    {
      employeeId: tokenData?.employeeId ?? "",
    },
    {
      onError: (err) => {
        dispatchToast({
          type: "error",
          content: err.data?.userFriendlyError?.message ?? "",
        })
      },
      enabled: !!tokenData?.employeeId,
    },
  )

  const permissions = React.useMemo(() => {
    const companyPermissions = allPermissions?.companies?.find(
      (company) => company.id === selectedCompany.id,
    )
    return companyPermissions?.permissions
  }, [allPermissions, selectedCompany.id])

  const profile: Profile | undefined = React.useMemo(() => {
    if (permissions?.some((permission) => adminSlugs.includes(permission)))
      return Profile.admin
    if (user?.isManager) return Profile.manager
    return undefined
  }, [permissions, user])

  React.useEffect(() => {
    async function decodeAccessToken() {
      const token = await getAccessToken()
      if (!token) throw new Error("NOT AUTHENTICATED")

      const decodedToken = decodeJwt<JwtPayload>(token)
      setTokenData(decodedToken)
    }

    decodeAccessToken()
  }, [selectedCompany])

  if (!user || !profile) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        $width={"100%"}
        $height={"100%"}
      >
        <Spinner size={64} />
      </Box>
    )
  }

  return (
    <globalContext.Provider
      value={{
        ...tokenData,
        user: user,
        profile,
      }}
    >
      {props.children}
    </globalContext.Provider>
  )
}

export { GlobalContext, globalContext }
