import { FeatureNotificationType } from ".."
import { StyledIframe } from "../styles"

type YoutubeContentProps = {
  value: FeatureNotificationType["modal"]["value"]
}

export const YoutubeContent = ({ value }: YoutubeContentProps) => {
  return (
    <StyledIframe
      src={value}
      title="YouTube video player"
      referrerPolicy="strict-origin-when-cross-origin"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  )
}
