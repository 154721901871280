import { Breadcrumbs, Stepper } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: ${({ theme }) => `${theme.spacings.xs1} ${theme.spacings.xs}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background-color: #fff;

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 715px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-left: ${({ theme }) => theme.spacings.xs2};
  min-width: 220px;
`

export const StyledStepper = styled(Stepper)`
  margin-left: ${({ theme }) => theme.spacings.xs};
  @media screen and (max-width: 715px) {
    margin-top: ${({ theme }) => theme.spacings.xs2};

    :first-child {
      margin-left: ${({ theme }) => theme.spacings.m};
    }
  }
`
