import { Box } from "@atoms"
import { UpdatedValueDisplayProps } from "@atoms/UpdatedValueDisplay"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { HistoryItem } from "@molecules/HistoryItem"
import VerticalTimeLine from "@organisms/VerticalTimeLine"
import { dayjs } from "@utils/dayjs"
import { useTheme } from "styled-components"

export interface RecognitionEvent {
  author: string
  name: string
  updatedAt: Date
  updatedValues?: UpdatedValueDisplayProps[]
}

interface RecognitionHistoryTabProps {
  events: RecognitionEvent[]
}

export const RecognitionHistoryTab = ({
  events,
}: RecognitionHistoryTabProps) => {
  const theme = useTheme()
  return (
    <VerticalTimeLine.Root>
      {events.map(({ author, name, updatedAt, updatedValues }, index) => (
        <VerticalTimeLine.Event.Root key={index}>
          <VerticalTimeLine.Event.LeftContent>
            <Box flexDirection="column" alignItems="flex-end" $mt="xs4">
              <Typography
                variant="caption"
                variantColor={theme.colors.neutral[70]}
              >
                {dayjs(updatedAt).format("DD/MM/YYYY")}
              </Typography>
              <Typography
                variant="caption"
                variantColor={theme.colors.neutral[70]}
              >
                {`às ${dayjs(updatedAt).format("HH:mm")}`}
              </Typography>
            </Box>
          </VerticalTimeLine.Event.LeftContent>
          <VerticalTimeLine.Event.RightContent>
            <HistoryItem
              title={name}
              author={author}
              updatedValues={updatedValues}
            />
          </VerticalTimeLine.Event.RightContent>
        </VerticalTimeLine.Event.Root>
      ))}
    </VerticalTimeLine.Root>
  )
}
