import { EditRecognition, CreateRecognition, Home } from "@pages"
import { internalRoutes } from "./internalRoutes"

export type RouteConfig = {
  path: string
  element: JSX.Element
  children?: Record<string, RouteConfig>
}

export const routesConfig: Record<string, RouteConfig> = {
  home: {
    path: internalRoutes.home.path,
    element: <Home />,
  },
  create: {
    path: internalRoutes.create.path,
    element: <CreateRecognition />,
  },
  edit: {
    path: internalRoutes.edit.path,
    element: <EditRecognition />,
  },
}

export type RoutesConfig = typeof routesConfig
