import { Box } from "@atoms"
import {
  GenericProfilePicture,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"

type CommentProps = {
  name: string
  picture?: string
  timeSince: string
  comment: string
}

const CommentWithMention = ({ comment }: { comment: string }) => {
  const theme = useTheme()

  const mentionRegex = /@\[(.*?)\]\((.*?)\)/g
  const mentions = comment.match(mentionRegex)

  const splitMentionsRegex = /(@\[(.*?)\]\(([^)]+)\))|([^@]+)/g
  const commentarySplitted = comment.match(splitMentionsRegex)

  if (!mentions || mentions?.length === 0 || !commentarySplitted)
    return (
      <Typography variant="body3" variantColor={theme.colors.neutral[50]}>
        {comment}
      </Typography>
    )

  return (
    <Box>
      {commentarySplitted.map((text, idx) => {
        if (text && mentions.includes(text)) {
          const mentionName = text.slice(
            text.indexOf("[") + 1,
            text.indexOf("]"),
          )
          return (
            <Typography
              key={`comment-${mentionName}-${idx}`}
              variant="body3"
              weight={600}
              variantColor={theme.colors.primary}
            >
              &nbsp;@{mentionName}&nbsp;
            </Typography>
          )
        }

        return (
          <Typography
            key={`comment-${idx}`}
            variant="body3"
            variantColor={theme.colors.neutral[50]}
          >
            {text}
          </Typography>
        )
      })}
    </Box>
  )
}

export const Comment = ({
  name,
  picture,
  timeSince,
  comment,
}: CommentProps) => {
  const theme = useTheme()

  return (
    <Box flexDirection="row" alignItems="center" gap="xs4">
      <GenericProfilePicture size={52} name={name} source={picture} />

      <Box flexDirection="column">
        <Box flexDirection="row" alignItems="center" gap="xs5">
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral[30]}
          >
            {name}
          </Typography>

          <Typography variant="caption" variantColor={theme.colors.neutral[70]}>
            {timeSince}
          </Typography>
        </Box>

        <CommentWithMention comment={comment} />
      </Box>
    </Box>
  )
}
