import { RecognitionType } from "@customTypes"
import i18n from "@i18n"
import { Filter } from "."
import { RECOGNITION_TYPE_DESCRIPTIONS } from "@utils/recognitionTypeDescriptions"

const typeOptions = Object.values(RecognitionType).map((value) => ({
  label: RECOGNITION_TYPE_DESCRIPTIONS[value],
  value: value,
}))

export const typeFilterName = "type"

export const typeFilter: Filter = {
  label: i18n.t("components.recognitionTable.filters.types.label"),
  name: typeFilterName,
  options: typeOptions,
  type: "radio",
}
