import { Box } from "@atoms"
import { Dialog } from "@mui/material"

type Props = { children?: React.ReactNode }

export const Root = ({ children }: Props) => {
  return (
    <Dialog open={true} keepMounted fullScreen transitionDuration={0}>
      <Box flexDirection="column" $height={"100vh"}>
        {children}
      </Box>
    </Dialog>
  )
}
