import { trpc } from "@api/client"
import { FeatureNotificationType } from ".."
import { S3Modules } from "server/src/types"
import { GifContainer } from "../styles"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

type GifContentProps = {
  value: FeatureNotificationType["modal"]["value"]
}

export const GifContent = ({ value }: GifContentProps) => {
  const { data: gif, isLoading: isLoadingGif } =
    trpc.helper.getPresignedUrl.useQuery(
      {
        fileName: value,
        module: S3Modules.recognition,
      },
      {
        refetchOnWindowFocus: false,
      },
    )

  return (
    <GifContainer>
      {!gif || isLoadingGif ? (
        <Skeleton variant="rectangular" width={"100%"} height={215} />
      ) : (
        <img alt="Feature tutorial" src={gif} />
      )}
    </GifContainer>
  )
}
