import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const StyledTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  alignitems: flex-start;
  flex: 1;
  overflow: hidden;
`
