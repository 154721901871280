import { Icons, IconTypes } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

type RoundedButtonProps = {
  icon?: IconTypes
  children: React.ReactNode
  onClick: React.HTMLAttributes<HTMLButtonElement>["onClick"]
  type?: "submit" | "button"
  className?: string
}

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;

  padding: ${({ theme }) => `${theme.spacings.xs} ${theme.spacings.xs2}`};
  height: ${({ theme }) => theme.spacings.m};
  gap: ${({ theme }) => theme.spacings.xs3};
  cursor: pointer;

  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 700;

  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: ${({ theme }) => theme.spacings.m};

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[80]};
  }
`

export const RoundedButton = ({
  children,
  onClick,
  icon,
  type,
  className,
}: RoundedButtonProps) => {
  return (
    <StyledButton className={className} type={type} onClick={onClick}>
      {icon && <Icons name={icon} fill="transparent" />}
      {children}
    </StyledButton>
  )
}
