import React from "react"
import { Comment } from "@customTypes"
import { Commentary } from "@organisms"
import { trpc } from "@api/client"
import dispatchToast from "@utils/dispatchToast"
import i18n from "@i18n"
import { globalContext } from "@context"
import _ from "lodash"

type RecognitionCommentary = {
  recognitionId: string
  comments: Comment[]
}

function parseComments(
  comments: Comment[],
): React.ComponentProps<typeof Commentary>["comments"] {
  if (!comments) return []

  const orderedComments = _.orderBy(
    comments,
    function (object) {
      return new Date(object.sentDate)
    },
    ["desc"],
  )
  const parsedComments = orderedComments.reduce(
    (prev, currComment, idx) => {
      if (!currComment.author) return prev

      return [
        ...prev,
        {
          order: idx,
          name: currComment.author?.name,
          picture: currComment.author.profilePicture,
          comment: currComment.message,
          timeSince: currComment.timeSince,
        },
      ]
    },
    [] as React.ComponentProps<typeof Commentary>["comments"],
  )

  return parsedComments
}

export const RecognitionCommentary = ({
  recognitionId,
  comments,
}: RecognitionCommentary) => {
  const { user } = React.useContext(globalContext)

  const utils = trpc.useUtils()

  const { mutateAsync: searchEmployee } =
    trpc.employee.searchEmployee.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  const { mutateAsync: postComment } = trpc.recognition.postComment.useMutation(
    {
      onMutate: async (comment) => {
        const previousData = utils.recognition.getRecognitionById.getData({
          recognitionId,
        })

        utils.recognition.getRecognitionById.setData(
          {
            recognitionId,
          },
          (oldQueryData) => {
            if (!oldQueryData) return undefined

            const comments: Comment[] = [
              ...(oldQueryData.comments || []),
              {
                authorEmployeeId: user.id,
                message: comment.message,
                sentDate: new Date(),
                timeSince: i18n.t("components.commentary.initialTimeSince"),
                author: {
                  id: user.id,
                  name: user.name,
                  profilePicture: user?.profilePicture ?? "",
                },
              },
            ]

            return {
              ...oldQueryData,
              comments,
            }
          },
        )

        return { previousData }
      },
      onSettled: () => {
        utils.recognition.getRecognitionById.invalidate({ recognitionId })
      },
      onError: (err, _, context) => {
        utils.recognition.getRecognitionById.setData(
          { recognitionId },
          context?.previousData,
        )

        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    },
  )

  const handleSubmitComment = async (comment: string) => {
    await postComment({
      recognitionId: recognitionId,
      message: comment,
    })
  }

  const parsedComments = parseComments(comments)

  const mentionCallback: React.ComponentProps<
    typeof Commentary
  >["mentionCallback"] = async (value) => {
    const employessFound = await searchEmployee({
      search: value,
    })
    return employessFound.map((employee) => ({
      id: employee.id,
      display: employee.name,
    }))
  }

  return (
    <Commentary
      onSubmit={handleSubmitComment}
      mentionCallback={mentionCallback}
      comments={parsedComments}
    />
  )
}
