import { TagDateFilter } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { CustomFieldProps } from "."

export const createdFromFilterName = "createdFrom"
export const createdToFilterName = "createdTo"

export const CreatedAtFilter = ({ handleChange }: CustomFieldProps) => {
  return (
    <TagDateFilter
      hasLeftIcon={false}
      variant="tertiary"
      filterLabel={
        i18n.t(
          "components.recognitionTable.filters.solicitationDate.label",
        ) as string
      }
      onSubmit={(values) => {
        handleChange(createdFromFilterName, values.from?.toISOString())
        handleChange(createdToFilterName, values.to?.toISOString())
      }}
    />
  )
}
