import { Box } from "@atoms"
import {
  Button,
  LinkButton,
  Modal,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { StyledModalContent, TextStyled } from "./styles"

interface ModalConfirmationProps {
  title: string | JSX.Element
  description: string | JSX.Element
  submitButtonLabel?: boolean
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void | Promise<void>
  isSubmiting?: boolean
}

export const ModalConfirmation = ({
  isOpen,
  onClose,
  description,
  title,
  onSubmit,
  isSubmiting = false,
  submitButtonLabel,
}: ModalConfirmationProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "components.modals.confirmationModal",
  })
  const theme = useTheme()

  return (
    <Modal.Root open={isOpen} onClose={onClose} size="xs">
      <>
        <StyledModalContent>
          <Box flexDirection="column" gap="xs2" alignItems="center">
            <ShapeIcon
              name={"IconFlag"}
              size={64}
              color={theme.colors.secondary[50]}
              variant={"default"}
            />
            <Typography
              variant="body3"
              weight={700}
              variantColor={theme.colors.secondary[50]}
            >
              {t("iconLabel")}
            </Typography>
            <TextStyled
              variant="headline6"
              weight={700}
              variantColor={theme.colors.neutral[20]}
            >
              {title}
            </TextStyled>
            <TextStyled
              variant="body3"
              weight={400}
              variantColor={theme.colors.neutral[50]}
            >
              {description}
            </TextStyled>
          </Box>
        </StyledModalContent>
        <Modal.Footer>
          <Box
            alignItems="center"
            gap="xs2"
            justifyContent="space-between"
            $width={"100%"}
          >
            <div>
              <LinkButton variant="default" onClick={onClose}>
                {t("footer.buttons.cancel")}
              </LinkButton>
            </div>
            <Button
              variant="primary"
              size="large"
              loading={isSubmiting}
              disabled={isSubmiting}
              onClick={onSubmit}
            >
              {submitButtonLabel || t("footer.buttons.submit")}
            </Button>
          </Box>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}
