import { Box } from "@atoms"
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import * as SC from "./styles"
export interface UpdatedValueDisplayProps {
  label?: string
  from?: string
  to: string
}

export const UpdatedValueDisplay = ({
  label,
  from,
  to,
}: UpdatedValueDisplayProps) => {
  const theme = useTheme()
  return (
    <Box flexDirection="column" gap="xs5">
      {!!label && (
        <Typography variant="caption" weight={600}>
          {label}
        </Typography>
      )}
      <Box gap="xs2">
        {!!from && (
          <>
            <SC.TextFrom variant="body4" weight={600}>
              {from}
            </SC.TextFrom>
            <Icons
              size={16}
              name="IconArrowRight"
              fill="transparent"
              color={theme.colors.neutral[70]}
            />
          </>
        )}
        <Typography variant="body4" weight={600}>
          {to}
        </Typography>
      </Box>
    </Box>
  )
}
