import React from "react"
import { LeftContent } from "./LeftContent"
import { RightContent } from "./RightContent"
import { TimeTrack, TimeTrackProps } from "./TimeTrack"

type EventProps = TimeTrackProps & {
  children:
    | React.ReactElement<typeof LeftContent>
    | React.ReactElement<typeof RightContent>
    | [
        React.ReactElement<typeof LeftContent>,
        React.ReactElement<typeof RightContent>,
      ]
}

const EventComponent = ({ highlighted, isLastEvent, children }: EventProps) => {
  let LeftChild: React.ReactNode
  let RightChild: React.ReactNode

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      switch (child.type) {
        case LeftContent:
          LeftChild = child
          break
        case RightContent:
          RightChild = child
          break
        default:
          break
      }
    }
  })

  return (
    <>
      {LeftChild}
      <TimeTrack highlighted={highlighted} isLastEvent={isLastEvent} />
      {RightChild}
    </>
  )
}

export const Event = {
  Root: EventComponent,
  LeftContent,
  RightContent,
}
