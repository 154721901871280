import React from "react"
import { Event } from "./Event"
import { VerticalTimeLineWrapperStyled } from "./styles"

type VerticalTimeLineRootProps = {
  children: React.ReactElement<typeof Event>[]
}

export const Root = ({ children }: VerticalTimeLineRootProps) => {
  const childrenCount = React.Children.count(children)
  const eventChildren = React.Children.map(
    children as React.ReactElement[],
    (child, index) => {
      return React.cloneElement(child, {
        highlighted: !index,
        isLastEvent: index === childrenCount - 1,
      })
    },
  )

  return (
    <VerticalTimeLineWrapperStyled>
      {eventChildren}
    </VerticalTimeLineWrapperStyled>
  )
}
