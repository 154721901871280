import { FindRecognitionById, RecognitionStatus } from "@customTypes"
import { dayjs, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Drawer } from "@organisms"
import { RECOGNITION_STATUS_DESCRIPTIONS } from "@utils/recognitionStatusDescriptions"
import { RECOGNITION_TYPE_DESCRIPTIONS } from "@utils/recognitionTypeDescriptions"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"

const statusTag: Record<
  RecognitionStatus,
  {
    variant: React.ComponentProps<typeof Drawer.Header>["tags"][0]["variant"]
    text: string
  }
> = {
  [RecognitionStatus.Approved]: {
    text: RECOGNITION_STATUS_DESCRIPTIONS[RecognitionStatus.Approved],
    variant: "green",
  },
  [RecognitionStatus.Canceled]: {
    text: RECOGNITION_STATUS_DESCRIPTIONS[RecognitionStatus.Canceled],
    variant: "yellow",
  },
  [RecognitionStatus.Rejected]: {
    text: RECOGNITION_STATUS_DESCRIPTIONS[RecognitionStatus.Rejected],
    variant: "yellow",
  },
  [RecognitionStatus.InProgress]: {
    text: RECOGNITION_STATUS_DESCRIPTIONS[RecognitionStatus.InProgress],
    variant: "blue",
  },
  [RecognitionStatus.NotStarted]: {
    text: RECOGNITION_STATUS_DESCRIPTIONS[RecognitionStatus.NotStarted],
    variant: "gray",
  },
}

type DrawerHeaderProps = React.ComponentProps<typeof Drawer.Header>

type HeaderProps = {
  recognition?: FindRecognitionById
  isLoading: boolean
  onClose: DrawerHeaderProps["onClose"]
}

export const Header = ({ recognition, isLoading, onClose }: HeaderProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionDetails",
  })

  return (
    <Drawer.Header
      title={t("title")}
      onClose={onClose}
      tags={recognition ? [statusTag[recognition.status]] : []}
      loading={isLoading}
      contents={[
        {
          title: t("createdAtLabel"),
          value: (
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral[30]}
            >
              {recognition?.createdAt
                ? dayjs(recognition?.createdAt).format("DD/MM/YYYY")
                : ""}
            </Typography>
          ),
        },
        {
          title: t("typeLabel"),
          value: (
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral[30]}
            >
              {recognition?.type
                ? RECOGNITION_TYPE_DESCRIPTIONS[recognition?.type]
                : ""}
            </Typography>
          ),
        },
        {
          title: t("personLabel"),
          value: <Tag variant="pink">{recognition?.employee.name}</Tag>,
        },
      ]}
    />
  )
}
