import { Box } from "@atoms"
import {
  UpdatedValueDisplay,
  UpdatedValueDisplayProps,
} from "@atoms/UpdatedValueDisplay"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"

interface HistoryItemProps {
  title: string
  author: string
  updatedValues?: UpdatedValueDisplayProps[]
}

export const HistoryItem = ({
  author,
  title,
  updatedValues,
}: HistoryItemProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "components.historyItem",
  })
  const theme = useTheme()
  return (
    <Box gap="xs4" flexDirection="column" $mb="s">
      <Typography variant="headline9">{title}</Typography>
      <Box gap={"xs4"}>
        <Typography
          variant="body4"
          variantColor={theme.colors.neutral[50]}
          weight={400}
        >
          {t("by")}
        </Typography>
        <Typography
          variant="body4"
          variantColor={theme.colors.secondary[40]}
          weight={400}
        >
          {author}
        </Typography>
      </Box>
      {updatedValues?.map(({ from, to, label }, index) => (
        <UpdatedValueDisplay key={index} from={from} to={to} label={label} />
      ))}
    </Box>
  )
}
