import { Box } from "@atoms/Box"
import { SelectField, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useDelayFn } from "@hooks"
import React from "react"
import { useTheme } from "styled-components"

type Option = { label: string; value: string }

type SelectRealTimeSearchProps = {
  value?: Option
  label?: string
  noOptionsLabel: string
  loadingOptionsLabel: string
  noSearchLabel: string
  minWordToSearch?: number
  onSearchCallback: (text?: string) => void
  onSelectChange: (option: Option) => void
  loading?: boolean
  options: Option[]
  required?: boolean
  disabled?: boolean
  error?: boolean
  helperText?: string
}

export const SelectRealTimeSearch = ({
  value,
  loading = false,
  label,
  noOptionsLabel,
  loadingOptionsLabel,
  minWordToSearch = 3,
  noSearchLabel,
  onSearchCallback,
  onSelectChange,
  error = false,
  required = false,
  disabled = false,
  helperText,
  options,
}: SelectRealTimeSearchProps) => {
  const [search, setSearch] = React.useState<string>("")

  const delayFn = useDelayFn()
  const theme = useTheme()

  const getOptionText = () => {
    if (search.length < minWordToSearch) return noSearchLabel
    if (search.length >= minWordToSearch && loading) return loadingOptionsLabel
    return noOptionsLabel
  }

  const getOptions = () => {
    if (value) return [value]
    if (search.length < minWordToSearch || loading) return []
    return options.filter((options) =>
      options.label.toUpperCase().includes(search.toUpperCase()),
    )
  }

  return (
    <SelectField
      searchable={true}
      fullWidth={true}
      required={required}
      disabled={disabled}
      label={
        <Box gap="xs5">
          {label}
          {required && (
            <Typography
              variant="body3"
              variantColor={theme.colors.feedback.error[40]}
            >
              *
            </Typography>
          )}
        </Box>
      }
      noOptionsText={getOptionText()}
      options={getOptions()}
      value={value || null}
      onSelectChange={(_e, option) => {
        setSearch("")
        onSelectChange(option ?? undefined)
      }}
      onBlur={(e) => {
        if (e.target.value === value?.value) return
        onSearchCallback(undefined)
      }}
      onChange={(e) => {
        delayFn(() => {
          setSearch(e.target.value)
          onSearchCallback(e.target.value)
        })
      }}
      error={error}
      helperText={error ? helperText : undefined}
    />
  )
}
