import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  gap: ${({ theme }) => theme.spacings.xs2};

  > div:nth-child(1) {
    flex: 1;
    overflow: hidden;
  }

  > div:nth-child(2) {
    flex: 0;
    width: auto;
  }
`
