import { trpc } from "@api/client"
import { Box } from "@atoms"
import { RecognitionStatus, RecognitionType, RouterOutput } from "@customTypes"
import { dayjs, Tab, TabItens } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { Drawer } from "@organisms"
import { stringToCurrencyParser } from "@utils"
import dispatchToast from "@utils/dispatchToast"
import { RECOGNITION_STATUS_DESCRIPTIONS } from "@utils/recognitionStatusDescriptions"
import { RECOGNITION_TYPE_DESCRIPTIONS } from "@utils/recognitionTypeDescriptions"
import { useTranslation } from "react-i18next"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { ContentSkeleton } from "./LoadingSkeleton"
import { OverviewTab } from "./OverviewTab"
import {
  RecognitionEvent,
  RecognitionHistoryTab,
} from "./RecognitionHistoryTab"
import { UpdatedValueDisplayProps } from "@atoms/UpdatedValueDisplay"

type RecognitionDetailsProps = {
  recognitionId: string
  open: boolean
  onClose: () => void
}

export type GetRecognitionType =
  RouterOutput["recognition"]["getRecognitionById"]

const getRecognitionEventModifier = (
  key: string,
): ((value?: any) => string) => {
  if (!key) {
    return () => ""
  }

  const modifier = {
    status: (status: string) =>
      RECOGNITION_STATUS_DESCRIPTIONS[status as RecognitionStatus] ?? status,
    type: (type: string) =>
      RECOGNITION_TYPE_DESCRIPTIONS[type as RecognitionType] ?? type,
    "salary.to": (value: string) => {
      const numberValue = Number(value)
      return stringToCurrencyParser(numberValue)
    },
  }[key]

  const defaultModifier = (value?: any) => {
    if (typeof value === "string") {
      return value
    }
    if (typeof value === "number") {
      return stringToCurrencyParser(value)
    }
    if (typeof value === "boolean") {
      return value ? "Sim" : "Nao"
    }
    if (value instanceof Date) {
      return dayjs().format("DD/MM/YYYY")
    }
    return ""
  }

  return modifier ?? defaultModifier
}

export const RecognitionDetails = ({
  recognitionId,
  open,
  onClose,
}: RecognitionDetailsProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionDetails",
  })

  const { data: recognition, isLoading: isGetRecognitionLoading } =
    trpc.recognition.getRecognitionById.useQuery(
      { recognitionId },
      {
        refetchOnWindowFocus: false,
        onError: (err) => {
          dispatchToast({
            content:
              err.data?.userFriendlyError?.message ??
              i18n.t("error.internalServerError"),
            type: "error",
          })
        },
      },
    )

  const {
    data: recognitionHistories,
    isLoading: isGetRecognitionHistoryLoading,
  } = trpc.recognition.getRecognitionHistoryById.useQuery(
    { recognitionId },
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    },
  )

  const recognitionHistoriesSorted =
    recognitionHistories?.sort((a, b) =>
      dayjs(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1,
    ) ?? []

  const recognitionEvents = recognitionHistoriesSorted.map<RecognitionEvent>(
    ({ action, changes, updatedBy, updatedAt }) => {
      return {
        name: action,
        author: updatedBy?.name ? `@${updatedBy.name}` : "",
        updatedAt: dayjs(updatedAt).toDate(),
        updatedValues: changes.map<UpdatedValueDisplayProps>(
          ({ label, oldValue, newValue, key }) => {
            const modifier = getRecognitionEventModifier(key)
            return {
              from: modifier(oldValue),
              to: modifier(newValue),
              label,
            }
          },
        ),
      }
    },
  )

  const tabs: TabItens[] = [
    {
      label: t("tabs.overview"),
      component: (
        <OverviewTab
          id={recognition?.id}
          communicationDate={recognition?.communicationDate}
          department={recognition?.department}
          effectiveDate={recognition?.effectiveDate}
          justification={recognition?.justification}
          manager={recognition?.manager}
          rejectReason={recognition?.rejectReason}
          requested={recognition?.requested}
          salary={recognition?.salary}
          status={recognition?.status}
          role={recognition?.role}
          comments={recognition?.comments}
        />
      ),
    },
    {
      label: t("tabs.processHistory"),
      component: <RecognitionHistoryTab events={recognitionEvents} />,
    },
  ]

  return (
    <>
      <Drawer.Root open={open}>
        <Header
          recognition={recognition}
          isLoading={isGetRecognitionLoading}
          onClose={onClose}
        />

        {isGetRecognitionLoading || isGetRecognitionHistoryLoading ? (
          <ContentSkeleton />
        ) : (
          <Drawer.Content>
            <Box flexDirection="column" $py="xs" $px="m" gap="xs">
              <Tab tabItens={tabs} />
            </Box>
          </Drawer.Content>
        )}

        {recognition && <Footer recognition={recognition} onClose={onClose} />}
      </Drawer.Root>
    </>
  )
}
