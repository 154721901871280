export const internalRoutes: Record<string, { path: string }> = {
  home: {
    path: "/recognition",
  },
  create: {
    path: "/recognition/create",
  },
  edit: {
    path: "/recognition/edit/:id",
  },
}
