type EType = "error" | "success" | "warning"

interface dispatchToastParams {
  content: string
  description?: string;
  type: EType
}

export default function dispatchToast(detail: dispatchToastParams) {
  dispatchEvent(new CustomEvent("showToast", { detail }))
}
