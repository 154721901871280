import { Mention, SuggestionDataItem } from "react-mentions"
import { StyledMentionsInput } from "./styles"
import { useDelayFn } from "@hooks"
import { Box } from "@atoms/Box"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import React from "react"

export const mentionStyle = {
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        fontWeight: 600,
        backgroundColor: "#f20d7a33",
      },
    },
  },
}

type MentionInput = {
  placeholder?: string
  value: string
  handleChange: (value: string) => void
  mentionCallback: (value: string) => Promise<SuggestionDataItem[]>
  maxLength?: number
  trigger?: React.ComponentProps<typeof Mention>["trigger"]
}

export const MentionInput = ({
  placeholder,
  value,
  handleChange,
  mentionCallback,
  maxLength = 100,
  trigger = "@",
}: MentionInput) => {
  const theme = useTheme()
  const delayFn = useDelayFn(700)

  const handleSearch = async (
    searchParam: string,
    callback: (data: SuggestionDataItem[]) => void,
  ) => {
    if (searchParam.length === 0) return
    delayFn(async () => {
      const value = await mentionCallback(searchParam)
      callback(value)
    })
  }

  return (
    <Box flexDirection="column" $width={"100%"}>
      <StyledMentionsInput
        placeholder={placeholder}
        onChange={(e) => {
          const value = e.target.value
          if (value.length > 100) return

          handleChange(value)
        }}
        value={value}
        style={mentionStyle}
        maxLength={maxLength}
        autoFocus={false}
        allowSpaceInQuery={true}
      >
        <Mention trigger={trigger} data={handleSearch} />
      </StyledMentionsInput>

      <Box justifyContent="flex-end">
        <Typography
          variant="caption"
          variantColor={theme.colors.neutral[50]}
          weight={600}
        >
          {value.length}/{maxLength}
        </Typography>
      </Box>
    </Box>
  )
}
