import React from "react"
import { Table } from "@flash-tecnologia/hros-web-ui-v2"
import { TableRecognitionAvailableStatus } from "../Table"
import { useTranslation } from "react-i18next"

import { statusFilter, statusFilterName } from "./StatusFilter"
import { typeFilter, typeFilterName } from "./TypeFilter"
import {
  CreatedAtFilter,
  createdToFilterName,
  createdFromFilterName,
} from "./CreatedAtFilter"
import { ManagerFilter, managerFilterName } from "./ManagerFilter"
import { Profile } from "@customTypes"

export type Filter = React.ComponentProps<typeof Table.Filters>["filters"][0]

const customFiltersNames = [
  managerFilterName,
  createdFromFilterName,
  createdToFilterName,
] as const

const defaultFilterNames = [typeFilterName, statusFilterName] as const

type CustomFilters = Partial<
  Record<(typeof customFiltersNames)[number], string>
>

type DefaultFilters = Partial<
  Record<(typeof defaultFilterNames)[number], string>
>

export type FiltersAvailable = DefaultFilters & CustomFilters

type FiltersProps = {
  profile: Profile
  status: TableRecognitionAvailableStatus
  handleChange: (filters: FiltersAvailable) => void
}

type handleCustomChange = (
  fieldName: keyof FiltersAvailable,
  value?: string,
) => void

export type CustomFieldProps = {
  handleChange: handleCustomChange
}

const getFilters = (
  profile: Profile,
  status: TableRecognitionAvailableStatus,
  handleCustomChange: handleCustomChange,
) => {
  if (profile === Profile.manager) {
    switch (status) {
      case TableRecognitionAvailableStatus.All:
        return [
          <CreatedAtFilter handleChange={handleCustomChange} />,
          typeFilter,
          statusFilter,
        ]
      case TableRecognitionAvailableStatus.Approved:
      case TableRecognitionAvailableStatus.InProgress:
      case TableRecognitionAvailableStatus.NotStarted:
      case TableRecognitionAvailableStatus.Rejected:
      default:
        return [
          <CreatedAtFilter handleChange={handleCustomChange} />,
          typeFilter,
        ]
    }
  }

  switch (status) {
    case TableRecognitionAvailableStatus.All:
      return [
        <CreatedAtFilter handleChange={handleCustomChange} />,
        typeFilter,
        statusFilter,
        <ManagerFilter handleChange={handleCustomChange} />,
      ]
    case TableRecognitionAvailableStatus.Approved:
    case TableRecognitionAvailableStatus.InProgress:
    case TableRecognitionAvailableStatus.NotStarted:
    case TableRecognitionAvailableStatus.Rejected:
    default:
      return [
        <CreatedAtFilter handleChange={handleCustomChange} />,
        typeFilter,
        <ManagerFilter handleChange={handleCustomChange} />,
      ]
  }
}

export const Filters = ({ profile, status, handleChange }: FiltersProps) => {
  const [localCustomFilterState, setLocalCustomFilterState] =
    React.useState<CustomFilters>({})
  const [localDefaultFilterState, setLocalDefaultFilterState] =
    React.useState<DefaultFilters>({})

  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionTable",
  })

  React.useEffect(() => {
    handleChange({ ...localCustomFilterState, ...localDefaultFilterState })
  }, [localCustomFilterState, localDefaultFilterState])

  const handleChangeCustomFilter = (fieldName: string, value?: string) => {
    setLocalCustomFilterState((prev) => ({
      ...prev,
      [fieldName]: value,
    }))
  }

  return (
    <Table.Filters
      label={t("filters.title")}
      onChange={(filters) => {
        if (!Array.isArray(filters)) return

        const state: DefaultFilters = {}
        defaultFilterNames.forEach((fieldName) => {
          const filterFound = filters.find(
            (filter) => filter.name === fieldName,
          )

          state[fieldName] = (filterFound?.values?.[0] as string) ?? undefined
        })

        setLocalDefaultFilterState(state)
      }}
      filters={getFilters(profile, status, handleChangeCustomFilter)}
    />
  )
}
