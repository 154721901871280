import { Box } from "@atoms"
import { Divider, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { dayjs } from "@utils/dayjs"
import { capitalize } from "lodash-es"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"

interface RecognitionApprovalSectionProps {
  effectiveDate: string
  communicationDate: string
}

export const RecognitionApprovalSection = ({
  communicationDate,
  effectiveDate,
}: RecognitionApprovalSectionProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionDetails",
  })
  return (
    <>
      <Divider orientation="horizontal" />
      <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
        {t("content.approval.title")}
      </Typography>

      <Box flexDirection="column">
        <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
          {t("content.approval.effectiveDate")}
        </Typography>
        <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
          {effectiveDate
            ? capitalize(dayjs(effectiveDate).format("MMMM, YYYY"))
            : ""}
        </Typography>
      </Box>

      <Box flexDirection="column">
        <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
          {t("content.approval.communicationDate")}
        </Typography>
        <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
          {communicationDate
            ? dayjs(communicationDate).format("DD/MM/YYYY")
            : communicationDate}
        </Typography>
      </Box>
    </>
  )
}
