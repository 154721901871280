import i18n from "@i18n"
import {
  FeatureNotificationType,
  FeatureNotificationTypesAvailable,
} from "@organisms"

export const features: FeatureNotificationType[] = [
  {
    identifier: "recognition",
    isLive: true,
    order: 0,
    modal: {
      title: i18n.t(
        "components.featureNotification.features.recognition.title",
      ),
      description: i18n.t(
        "components.featureNotification.features.recognition.description",
      ),
      type: FeatureNotificationTypesAvailable.youtube,
      value: "https://www.youtube.com/embed/gcCs3f4eVXU?si=1UiZmuxYkdlTxJRR",
    },
  },
]
