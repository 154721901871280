import styled, { DefaultTheme } from "styled-components"
import { Tag } from "@flash-tecnologia/hros-web-ui-v2"
import { Box } from "@atoms"

type ItemBoxProps = {
  maxWidth?: keyof DefaultTheme["spacings"]
}

export const ItemBox = styled(Box)<ItemBoxProps>`
  width: max-content;
  max-width: ${({ theme, maxWidth = "xl" }) => theme.spacings[maxWidth]};
`

export const MaxContentTag = styled(Tag)`
  width: max-content;
`

export const ThreeLineSpan = styled.span`
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`

export const EllipsisSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
