import { Box } from "@atoms"
import { Divider, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"

interface RecognitionRejectionSectionProps {
  rejectReason: string
}

export const RecognitionRejectionSection = ({
  rejectReason,
}: RecognitionRejectionSectionProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionDetails",
  })
  return (
    <>
      <Divider orientation="horizontal" />
      <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
        {t("content.rejection.title")}
      </Typography>

      <Box flexDirection="column">
        <Typography variant="caption" variantColor={theme.colors.neutral[50]}>
          {t("content.rejection.rejectReason")}
        </Typography>
        <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
          {rejectReason}
        </Typography>
      </Box>
    </>
  )
}
