import React from "react"
import { BigNumber } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

const StyledBigNumber = styled(BigNumber)<{ textColor?: string }>`
  max-width: none;
  flex: none;

  & > :last-child {
    flex: 1;
    gap: 0;

    > h3 {
      color: ${({ textColor }) => textColor};
    }
  }
`

type HorizontalBigNumberProps = Omit<
  React.ComponentProps<typeof BigNumber>,
  "orientation" | "button"
> & {
  textColor?: string
}

export const HorizontalBigNumber = ({
  icon,
  iconColors,
  title,
  value,
  className,
  textColor,
  ...rest
}: HorizontalBigNumberProps) => {
  return (
    <StyledBigNumber
      className={className}
      orientation="horizontal"
      iconColors={iconColors}
      icon={icon}
      title={title}
      value={value}
      button={<></>}
      textColor={textColor}
      {...rest}
    />
  )
}
