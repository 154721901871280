import { useState, useEffect } from "react"

const getStoragedValue = <T>(key: string) => {
  const storagedValue = localStorage.getItem(key)

  return storagedValue ? (JSON.parse(storagedValue) as T) : null
}

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [value, setValue] = useState<T>(getStoragedValue(key) || initialValue)

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value, key])

  return [value, setValue] as const
}
