import styled from "styled-components"

type Props = { children?: React.ReactNode }

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  width: 100%;
`

export const Content = ({ children }: Props) => {
  return <Container>{children}</Container>
}
