import { Box } from "@atoms"
import styled from "styled-components"
import { TimeTrackProps } from "."

const TIME_TRACK_MILESTONE_SIZE = 12

const TIME_TRACK_MILESTONE_HIGHLIGHTED_BORDER = 2

export const TIME_TRACK_MILESTONE_HIGHLIGHTED_SIZE =
  TIME_TRACK_MILESTONE_SIZE + 2 * TIME_TRACK_MILESTONE_HIGHLIGHTED_BORDER

const TIME_TRACK_MILESTONE_ABSOLUTE_POSITION = "20px"

export const TimeTrackStyled = styled.div<TimeTrackProps>`
  width: ${TIME_TRACK_MILESTONE_SIZE}px;
  height: ${TIME_TRACK_MILESTONE_SIZE}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.neutral[90]};

  ${({ highlighted, theme }) =>
    highlighted
      ? `
  width: ${TIME_TRACK_MILESTONE_HIGHLIGHTED_SIZE}px;
  height: ${TIME_TRACK_MILESTONE_HIGHLIGHTED_SIZE}px;
  background-color: transparent;
  border: ${TIME_TRACK_MILESTONE_HIGHLIGHTED_BORDER}px solid ${theme.colors.secondary[70]};
    `
      : ""}

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: ${TIME_TRACK_MILESTONE_HIGHLIGHTED_BORDER}px;
    background-color: ${({ theme }) => theme.colors.neutral[90]};
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ isLastEvent, highlighted }) =>
    !isLastEvent
      ? `
  &::after {
    bottom: 0;
    height: calc(100% - ${highlighted ? `${TIME_TRACK_MILESTONE_HIGHLIGHTED_SIZE}px` : `${TIME_TRACK_MILESTONE_SIZE}px`} - ${TIME_TRACK_MILESTONE_ABSOLUTE_POSITION});
  }`
      : ""}
`

export const TimeTrackWrapperStyled = styled(Box)<TimeTrackProps>`
  align-self: stretch;
  position: relative;
  width: ${TIME_TRACK_MILESTONE_HIGHLIGHTED_SIZE}px;

  padding-top: ${TIME_TRACK_MILESTONE_ABSOLUTE_POSITION};

  & ~ & ${TimeTrackStyled} {
    &::before {
      top: 0;
      height: calc(
        ${({ highlighted }) =>
            highlighted
              ? `${TIME_TRACK_MILESTONE_HIGHLIGHTED_SIZE}px`
              : `${TIME_TRACK_MILESTONE_SIZE}px`} +
          ${TIME_TRACK_MILESTONE_ABSOLUTE_POSITION}
      );
    }
  }
`
